import { CreateCustomerDto, EditCustomerDto } from "../../../models/Customer";

export interface CustomerForm {
  id?: number | null;
  customerId: string;
  cpfcnpj: string;
  name: string;
  adobeName: string;
  isJuridicPerson: boolean;
  country: string;
  cep: string;
  city: string;
  state: string;
  address: string;
  neighborhood: string;
  number: string;
  adressComplement?: string;
  isThreeYears: boolean;
  segment: string;
  adobeStatus: string;
  durationContract: string;
  typeClassProduct: string;
  resellerId: string;

  signingResponsibleName: string;
  signingResponsibleTypePhone: string;
  signingResponsibleNumber: string;
  signingResponsibleEmail: string;

  financialResponsibleName: string;
  financialResponsibleTypePhone: string;
  financialResponsibleNumber: string;
  financialResponsibleEmail: string;

  purchasingResponsibleName: string;
  purchasingResponsibleTypePhone: string;
  purchasingResponsibleNumber: string;
  purchasingResponsibleEmail: string;

  stateRegistration: string;
  municipalRegistration: string;
}

export interface EditCustomerForm {
  id: number;
  customerId: string;
  cpfcnpj: string;
  name: string;
  adobeName: string;
  isJuridicPerson: boolean;
  country: string;
  cep: string;
  city: string;
  state: string;
  address: string;
  neighborhood: string;
  number: string;
  adressComplement?: string;
  isThreeYears: boolean;
  segment: string;
  adobeStatus: string;
  durationContract: string;
  typeClassProduct: string;
  resellerId: string;
  resellerAdobeId: string;
  resellerName: string;
  cotermDate: string;
  dateCreated: string;
  proRata: string;
  licenseLevel: string;
  transactionLevel: string;

  custId: string;
  ResId: string;

  signingResponsibleName: string;
  signingResponsibleTypePhone: string;
  signingResponsibleNumber: string;
  signingResponsibleEmail: string;

  financialResponsibleName: string;
  financialResponsibleTypePhone: string;
  financialResponsibleNumber: string;
  financialResponsibleEmail: string;

  purchasingResponsibleName: string;
  purchasingResponsibleTypePhone: string;
  purchasingResponsibleNumber: string;
  purchasingResponsibleEmail: string;
  products: CustomerActiveProduct[];

  hasPendingRenewal: boolean;
  stateRegistration: string;
  municipalRegistration: string;
}
export interface CustomerActiveProduct {
  id: number;
  customerId: number;
  productId: number;
  productName: string;
  sku: string;
  subscriptionId: string;
  usedQuantity: number;
  currentQuantity: number;
  autoRenewal: boolean;
  quantityRenewal: number;
}

export const toCreateCustomerDto = (
  formData: CustomerForm
): CreateCustomerDto => {
  const {
    durationContract,
    segment,
    id,
    resellerId,
    customerId,
    signingResponsibleName,
    signingResponsibleTypePhone,
    signingResponsibleNumber,
    signingResponsibleEmail,

    financialResponsibleName,
    financialResponsibleTypePhone,
    financialResponsibleNumber,
    financialResponsibleEmail,

    purchasingResponsibleName,
    purchasingResponsibleTypePhone,
    purchasingResponsibleNumber,
    purchasingResponsibleEmail,
    stateRegistration,
    municipalRegistration,
    ...data
  } = formData;

  let contacts = [
    {
      name: signingResponsibleName,
      typeContact: "Signature",
      typePhone: signingResponsibleTypePhone,
      phoneNumber: signingResponsibleNumber,
      email: signingResponsibleEmail,
      customerId: parseInt(customerId),
    }];

  if (financialResponsibleName.trim().length > 0) {
    contacts.push(
      {
        name: financialResponsibleName,
        typeContact: "Financial",
        typePhone: financialResponsibleTypePhone,
        phoneNumber: financialResponsibleNumber,
        email: financialResponsibleEmail,
        customerId: parseInt(customerId),
      }
    );
  }

  if (purchasingResponsibleName.trim().length > 0) {
    contacts.push(
      {
        name: purchasingResponsibleName,
        typeContact: "Purchasing",
        typePhone: purchasingResponsibleTypePhone,
        phoneNumber: purchasingResponsibleNumber,
        email: purchasingResponsibleEmail,
        customerId: parseInt(customerId),
      }
    );
  }

  const customer: CreateCustomerDto = {
    customerId: customerId,
    resellerId: parseInt(resellerId),
    durationContract: durationContract,
    marketSegment: segment,
    customerContacts: contacts,
    stateRegistration: stateRegistration,
    municipalRegistration: municipalRegistration,
    ...data,
  };

  return customer;
};

export const toEditCustomerDto = (
  formData: EditCustomerForm
): EditCustomerDto => {
  const {
    ResId,
    custId,
    segment,
    id,
    resellerId,
    resellerName,
    resellerAdobeId,
    customerId,
    signingResponsibleName,
    signingResponsibleTypePhone,
    signingResponsibleNumber,
    signingResponsibleEmail,

    financialResponsibleName,
    financialResponsibleTypePhone,
    financialResponsibleNumber,
    financialResponsibleEmail,

    purchasingResponsibleName,
    purchasingResponsibleTypePhone,
    purchasingResponsibleNumber,
    purchasingResponsibleEmail,
    products,
    stateRegistration,
    municipalRegistration,
    ...data
  } = formData;
  const contacts = [
    {
      name: signingResponsibleName,
      typeContact: "Signature",
      typePhone: signingResponsibleTypePhone,
      phoneNumber: signingResponsibleNumber,
      email: signingResponsibleEmail,
      customerId: parseInt(customerId),
    }
  ];

  if (financialResponsibleName.trim().length > 0) {
    contacts.push(
      {
        name: financialResponsibleName,
        typeContact: "Financial",
        typePhone: financialResponsibleTypePhone,
        phoneNumber: financialResponsibleNumber,
        email: financialResponsibleEmail,
        customerId: parseInt(customerId),
      }
    );
  }

  if (purchasingResponsibleName.trim().length > 0) {
    contacts.push(
      {
        name: purchasingResponsibleName,
        typeContact: "Purchasing",
        typePhone: purchasingResponsibleTypePhone,
        phoneNumber: purchasingResponsibleNumber,
        email: purchasingResponsibleEmail,
        customerId: parseInt(customerId),
      }
    );
  }

  const customer: EditCustomerDto = {
    id: id,
    customerId: customerId,
    resellerId: parseInt(resellerId),
    marketSegment: segment,
    customerContacts: contacts,
    products: products.map((product) => ({
      id: product.id,
      customerId: product.customerId,
      productId: product.productId,
      productName: product.productName,
      sku: product.sku,
      subscriptionId: product.subscriptionId,
      usedQuantity: product.usedQuantity,
      currentQuantity: product.currentQuantity,
      autoRenewal: product.autoRenewal,
      quantityRenewal: product.quantityRenewal,
    })),
    stateRegistration: stateRegistration,
    municipalRegistration: municipalRegistration,
    ...data,
  };

  return customer;
};
