import React, { useEffect, useMemo, useState } from "react";
import { MainTemplate } from "../../../components/templates/MainTemplate";
import { OrderStatusFooter } from "./OrderStatusFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../components/atoms/Toast";
import { Order } from "../../../models/Order";
import {
  approveCreditAnalysis,
  getOrderById,
  rejectCreditAnalysis,
} from "../../../services/api/OrderService";
import { Loading } from "../../../components/orgs/Loading";
import { orderStatus, orderStatusMap } from "../../../models/enums/orderStatus";
import { orderType } from "../../../models/enums/orderType";
import { OrderStatusHeader } from "./OrderStatusHeader";
import { OrderStatusDataTable } from "./OrderStatusDataTable";
import { OrderNotFound } from "./OrderNotFound";
import { ProfileCode } from "../../../models/enums/ProfileCode";
import { OrderItem } from "../../../models/OrderItem";
import moment from "moment";

export interface OrderStatusResponse {
  orderId: number;
  date?: Date | string | null;
  reseller: string | null;
  customer: string | null;
  proRata?: number | null;
  cotermDate?: Date | string | null;
  type?: orderType | null;
  customerOrder?: string | null;
  resellerOrder?: string | null;
  paymentCondition?: string | null;
  billingFor?: string | null;
  responsible?: string | null;
  adobeOrderId?: string | null;
  status?: orderStatus | null;
  resellerCnpj?: string | null;
  customerCnpj?: string | null;
}

export const OrderStatus: React.FC = () => {
  const { id: orderId } = useParams();
  const Toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order>();
  const [comments, setComments] = useState<string>("");
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;

  const orderDetail = useMemo((): OrderStatusResponse => {
    if (!order) {
      return {
        orderId: 0,
        date: null,
        reseller: "",
        customer: "",
        proRata: null,
        cotermDate: null,
        type: null,
        customerOrder: null,
        resellerOrder: null,
        paymentCondition: null,
        billingFor: null,
        responsible: null,
        adobeOrderId: null,
        status: null,
        resellerCnpj: null,
        customerCnpj: null,
      };
    }

    const { customer, reseller } = order;

    return {
      orderId: order.id,
      date: moment(order.dateCreated).format("YYYY-MM-DD"),
      reseller: reseller
        ? `${reseller.resellerId} - ${reseller.corporateName}`
        : null,
      customer: customer ? `${customer.customerId} - ${customer.name}` : null,
      proRata: order.proRata,
      cotermDate: customer?.cotermDate
        ? moment(customer.cotermDate).format("YYYY-MM-DD")
        : null,
      type: order.isRenewal ? orderType.Renewal : orderType.New,
      customerOrder: order.pedCustomer || null,
      resellerOrder: order.pedReseller || null,
      paymentCondition: order.paymentCondition || null,
      billingFor: order.billingFor?.toString() || null,
      responsible: order.user?.name || null,
      adobeOrderId: order.adobeOrderId || null,
      status: order.status || null,
      resellerCnpj: order.reseller?.cnpj || null,
      customerCnpj: order.customer?.cpfcnpj || null
    };
  }, [order]);

  const orderItems = useMemo((): OrderItem[] => {
    if (!order || !order.items) return [];

    return order.items;
  }, [order]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!orderId) return;
        const order = await getOrderById(Number(orderId));
        setOrder(order);

        const creditAnalysisStatus = orderStatusMap[orderStatus.CreditAnalysis];

        setCanApprove(
          (profile.profileCode === ProfileCode.SuperAdminPars ||
            profile.profileCode === ProfileCode.AdminPars ||
            profile.profileCode === ProfileCode.FaturamentoPars) &&
            order?.status?.toString() === creditAnalysisStatus.id
        );
      } catch (error) {
        if (error instanceof Error) {
          return;
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId, profile.profileCode]);

  const handleApprove = async (
    comments: string,
    orderId: number
  ): Promise<void> => {
    setComments(comments);

    if (!order) return;

    try {
      await approveCreditAnalysis(orderId, comments);
      Toast.fire({ icon: "success", title: "Análise de crédito aprovada!" });
      navigate("/orders/history", { replace: true });
    } catch (error) {
      if (error instanceof Error) {
        Toast.fire({ icon: "error", title: error.message });
      }
    }
  };

  const handleReject = async (
    comments: string,
    orderId: number
  ): Promise<void> => {
    setComments(comments);

    if (!order) return;

    try {
      await rejectCreditAnalysis(orderId, comments);
      Toast.fire({ icon: "success", title: "Análise de crédito reprovada!" });
      navigate("/orders/history", { replace: true });
    } catch (error) {
      if (error instanceof Error) {
        Toast.fire({ icon: "error", title: error.message });
      }
    }
  };

  if (isLoading) return <Loading />;
  if (!order) return <OrderNotFound />;

  return (
    <MainTemplate>
      <OrderStatusHeader data={orderDetail} />

      <OrderStatusDataTable order={order} data={orderItems} />

      {canApprove && (
        <OrderStatusFooter
          data={comments}
          orderId={order.id}
          onApprove={handleApprove}
          onReject={handleReject}
        />
      )}
    </MainTemplate>
  );
};
