import React from "react";
import { ProfileCode } from "../../../models/enums/ProfileCode";

interface CustomerDetailsProps {
  customerId?: string;
  name?: string;
  prorata?: number;
}

export const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  customerId,
  name,
  prorata,
}) => {
  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;
  const hasReadResellerProfile = profile.profileCode == ProfileCode.ConsultaRevenda;
  return (
    <div className="row">
      <div className="col col-3 py-2">
        <strong>Customer ID: </strong>
        <span>{customerId}</span>
      </div>

      <div className="col col-5 py-2">
        <strong>Cliente: </strong>
        <span>{name}</span>
      </div>

      <div className="col col-2 py-2">
        <strong>Pro Rata: </strong>
        <span>{prorata}</span>
      </div>

      {!hasReadResellerProfile && (
        <div className="col col-2">
          <a href="/order/cart" className="btn btn-primary d-block w-100">
           Visualizar carrinho
          </a>
        </div>
         )}
    </div>
  );
};
