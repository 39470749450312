import React, { useEffect, useState } from "react";
import { Card } from "../../../components/mols/Card";
import { useToast } from "../../../components/atoms/Toast";
import { getActiveProductsByCustomerId } from "../../../services/api/CustomerService";
import { SwitchBtn } from "../../../components/atoms/SwitchBtn";
import { CustomerActiveProduct } from "../../../models/Customer";

interface CustomerActiveProductsProps {
  customerId: number;
  isActive: boolean;
  onUpdateProduct?: (updatedProduct: CustomerActiveProduct) => void;
}

export const CustomerActiveProducts: React.FC<CustomerActiveProductsProps> = ({
  customerId,
  isActive = false,
  onUpdateProduct,
}) => {
  const Toast = useToast();
  const [products, setProducts] = useState<CustomerActiveProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 5;

  const loadProducts = async () => {
    setLoading(true);
    try {
      const result = await getActiveProductsByCustomerId(customerId);
      if (result) {
        setProducts(result);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.fire({
          icon: "error",
          title: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, [customerId]);

  const handleToggle = (product: CustomerActiveProduct, checked: boolean) => {
    const updatedProduct = { ...product, autoRenewal: checked };

    setProducts((prevProducts) =>
      prevProducts.map((item) =>
        item.id === updatedProduct.id ? updatedProduct : item
      )
    );

    if (onUpdateProduct) {
      onUpdateProduct(updatedProduct);
    }
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / pageSize);

  return (
    <Card>
      <div className="table-responsive">
        {loading ? (
          <div className="text-center">Carregando...</div>
        ) : (
          <>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>SKU</th>
                  <th>Licenças Processadas</th>
                  <th>Disponível</th>
                  <th>Renovação Automática</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.length > 0 ? (
                  currentProducts.map((product) => {
                    const availableQuantity =
                      product.currentQuantity - product.usedQuantity;

                    return (
                      <tr key={product.id}>
                        <td>{product.productName}</td>
                        <td>{product.sku}</td>
                        <td className="text-center">
                          {product.currentQuantity}
                        </td>
                        <td className="text-center">
                          {availableQuantity < 0 ? 0 : availableQuantity}
                        </td>
                        <td className="text-center">
                          <SwitchBtn
                            inline
                            checked={product.autoRenewal}
                            disabled={!isActive}
                            onChange={(checked) =>
                              handleToggle(product, checked)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Paginação */}
            <div className="d-flex justify-content-end mt-3">
              <nav>
                <ul className="pagination pagination-sm mb-0">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(1)}
                    >
                      Primeiro
                    </button>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Anterior
                    </button>
                  </li>
                  <li className="page-item active">
                    <span className="page-link">{currentPage}</span>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Próximo
                    </button>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(totalPages)}
                    >
                      Último
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
