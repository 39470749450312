export enum orderType {
  All = "-1",
  New = "0",
  Renewal = "1",
}

export const toOrderTypeText = (id: string): string => {
  switch (id) {
    case "New":
      return "Novo";

    case "Renewal":
      return "Renovação";

    case "Rma":
      return "RMA";

    default:
      return "Desconhecido";
  }
};
