import { AxiosError } from "axios";
import {
  Company,
  CreateCustomerDto,
  Customer,
  CustomerActiveProduct, CustomerContacts,
  CustomerOrders,
  EditCustomerDto
} from "../../models/Customer";
import { BaseListResponse } from "../../models/Base";
import { DTData } from "../../models/DTData";

import apiClient, { ErrorApi, jsonConfig } from "./ApiClient";
import { CustomerFilters } from "../../models/CustomerFilters";

export const getCustomersByReseller = async (
  resellerId: number | null,
  page: number,
  size: number,
  filter: string
): Promise<DTData<CustomerOrders>> => {
  let url =
    resellerId === null
      ? `/customers?filter=${filter}&&removeEmptyCustomerId=true`
      : `resellers/${resellerId}/customers?customerFilter=${filter}&&removeEmptyCustomerId=true`;
  url += `&page=${page}&size=${size}`;

  const response = await apiClient.get(url);
  const { content: customers, total } = response.data;

  const data: DTData<CustomerOrders> = {
    data: customers,
    recordsFiltered: total,
    recordsTotal: customers.length,
  };

  return data;
};

const formUrlFilters = (filters: CustomerFilters): string => {
  const prorata =
    !filters.prorataValue || filters.prorataValue === ""
      ? undefined
      : parseInt(filters.prorataValue, 10);

  let url = "";
  if (filters.startCotermDate)
    url += `&startCotermDate=${filters.startCotermDate}`;
  if (filters.endCotermDate) url += `&endCotermDate=${filters.endCotermDate}`;
  if (filters.status && filters.status !== "0")
    url += "&status=" + filters.status;
  if (prorata) url += "&prorata=" + prorata;
  if (filters.customer) url += "&filter=" + filters.customer;
  if (filters.resellerId && filters.resellerId !== "")
    url += "&ResellerID=" + Number(filters.resellerId);
  return url;
};

export const getCustomer = async (
  page: number,
  size: number,
  filters: CustomerFilters
): Promise<DTData<Customer>> => {
  const urlFilters = formUrlFilters(filters);
  const url = `/customers?page=${page}&size=${size}${urlFilters}`;
  const { data } = await apiClient.get<any>(url);

  let customers: Customer[] = data.content ?? [];
  customers.sort((a, b) => a.name.localeCompare(b.name));

  return {
    data: customers,
    recordsTotal: customers.length,
    recordsFiltered: data.total,
  };
};

export const getCustomerById = async (
  id: BigInt | number
): Promise<Customer> => {
  let response = await apiClient.get<Customer>("/customers/" + id);

  return response.data as unknown as Customer;
};

export const getByCnpj = async (cnpj: string): Promise<Company> => {
  let response = await apiClient.get<Company>("public-data/cnpj/" + cnpj);

  return response.data as unknown as Company;
};

export const addCustomer = async (
  customer: CreateCustomerDto
): Promise<string> => {
  return new Promise(async (res, rej) => {
    await apiClient
      .put("/customers", customer, jsonConfig)
      .then(() => {
        res("");
      })
      .catch((error: string) => {
        rej(error);
      });
  });
};

export const resendAdminInvite = async (
    customerContact: CustomerContacts
): Promise<void> => {
  await apiClient.post("/customers/resend-admin-invite", customerContact);
};


export const editCustomer = async (
    customer: EditCustomerDto
): Promise<string> => {
  return new Promise(async (res, rej) => {
    await apiClient
        .post("/customers", customer, jsonConfig)
        .then(() => {
          res("");
        })
        .catch((error: string) => {
          rej(error);
        });
  });
};

export const autocompleteCustomers = async (
  search: string
): Promise<Customer[]> => {
  try {
    let url = `/customers?filter=${search}`;
    let response = await apiClient.get<BaseListResponse<Customer>>(url);

    return response.data.content;
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data as ErrorApi | undefined;
      console.error(
        "Não foi possível carregar a lista clientes:",
        err?.message
      );
    }
    throw new Error("Não foi possível carregar a lista clientes.");
  }
};

export const getActiveProductsByCustomerId = async (
  customerId: number
): Promise<CustomerActiveProduct[]> => {
  const url = `/customers/${customerId}/active-products`;
  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar produtos ativos:", error);
    throw error;
  }
};
