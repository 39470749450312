import { MainTemplate } from "../../components/templates/MainTemplate";
import { Input } from "../../components/atoms/Input";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-bs5";
import React, { useEffect, useState } from "react";
import { useToast } from "../../components/atoms/Toast";
import { Accordion } from "../../components/mols/Accordion";
import { AccordionItem } from "../../components/atoms/AccordionItem";
import { Select } from "../../components/atoms/Select";
import { SwitchBtn } from "../../components/atoms/SwitchBtn";
import { getCustomerById } from "../../services/api/CustomerService";
import { useGetResellers } from "../../hooks/resellerHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/orgs/Loading";
import { toOptionsProps } from "../../models/OptionsProps";
import { CustomerContacts } from "./components/CustomerContacts";
import { CustomerOrderHistory } from "./components/CustomerOrderHistory";
import { CustomerActiveProducts } from "./components/CustomerActiveProducts";
import { useForm } from "../../hooks/formHook";
import { EditCustomerForm } from "./models/CustomerForm";

DataTable.use(DT);

const DetailCustomer: React.FC = () => {
  const { id: idStr } = useParams();
  const id = BigInt(idStr ?? 0);
  const toast = useToast();
  const { data: resellers } = useGetResellers();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<EditCustomerForm>({
    id: 0,
    customerId: "",
    cpfcnpj: "",
    name: "",
    adobeName: "",
    isJuridicPerson: true,
    country: "",
    cep: "",
    city: "",
    state: "",
    address: "",
    neighborhood: "",
    number: "",
    adressComplement: "",
    isThreeYears: false,
    segment: "",
    adobeStatus: "",
    durationContract: "",
    typeClassProduct: "",
    resellerId: "",
    resellerName: "",
    resellerAdobeId: "",
    cotermDate: "",
    dateCreated: "",
    proRata: "",
    custId: "",
    ResId: "",
    licenseLevel: "",
    transactionLevel: "",
    signingResponsibleName: "",
    signingResponsibleTypePhone: "",
    signingResponsibleNumber: "",
    signingResponsibleEmail: "",
    financialResponsibleName: "",
    financialResponsibleTypePhone: "",
    financialResponsibleNumber: "",
    financialResponsibleEmail: "",
    purchasingResponsibleName: "",
    purchasingResponsibleTypePhone: "",
    purchasingResponsibleNumber: "",
    purchasingResponsibleEmail: "",
    products: [],
    hasPendingRenewal: false,
    stateRegistration: "",
    municipalRegistration: ""
  });

  const { handleError } = useForm<EditCustomerForm>({
    setFormData,
  });

  const FillInFieldsCustomerId = (id: BigInt) => {
    setIsLoading(true);
    getCustomerById(id)
      .then((response) => {
        setFormData({
          ...formData,
          id: Number(id),
          customerId: response.customerId,
          cpfcnpj: response.cpfcnpj,
          name: response.name,
          adobeName: response.adobeName,
          isJuridicPerson: response.isJuridicPerson,
          country: response.country,
          cep: response.cep,
          city: response.city,
          state: response.state,
          address: response.address,
          neighborhood: response.neighborhood,
          number: response.number,
          adressComplement: response.adressComplement,
          isThreeYears: response.isThreeYears,
          adobeStatus: response.adobeStatus,
          durationContract: response.durationContract,
          typeClassProduct: response.typeClassProduct,
          resellerId: String(response.resellerId),
          resellerName: `${response.resellerName + " - " + response.resellerAdobeId}`,
          proRata: String(response.proRata),
          segment: response.marketSegment,
          cotermDate: String(response.cotermDate) || "",
          dateCreated: String(response.dateCreated) || "",
          ResId: String(response.resellerId),
          custId: response.customerId,
          licenseLevel: response.licenseLevel || "",
          transactionLevel: response.transactionLevel || "",

          signingResponsibleName:
            response.customerContacts?.find(item => item.typeContact === "Signature")?.name || "",
          signingResponsibleTypePhone:
            response.customerContacts?.find(item => item.typeContact === "Signature")?.typePhone || "",
          signingResponsibleNumber:
            response.customerContacts?.find(item => item.typeContact === "Signature")?.phoneNumber || "",
          signingResponsibleEmail:
            response.customerContacts?.find(item => item.typeContact === "Signature")?.email || "",
          financialResponsibleName:
            response.customerContacts?.find(item => item.typeContact === "Financial")?.name || "",
          financialResponsibleTypePhone:
            response.customerContacts?.find(item => item.typeContact === "Financial")?.typePhone || "",
          financialResponsibleNumber:
            response.customerContacts?.find(item => item.typeContact === "Financial")?.phoneNumber || "",
          financialResponsibleEmail:
            response.customerContacts?.find(item => item.typeContact === "Financial")?.email || "",
          purchasingResponsibleName:
            response.customerContacts?.find(item => item.typeContact === "Purchasing")?.name || "",
          purchasingResponsibleTypePhone:
            response.customerContacts?.find(item => item.typeContact === "Purchasing")?.typePhone || "",
          purchasingResponsibleNumber:
            response.customerContacts?.find(item => item.typeContact === "Purchasing")?.phoneNumber || "",
          purchasingResponsibleEmail:
            response.customerContacts?.find(item => item.typeContact === "Purchasing")?.email || "",

          hasPendingRenewal: response.hasPendingRenewal,
          municipalRegistration: response.municipalRegistration,
          stateRegistration: response.stateRegistration
        });
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error, toast, "buscar informação do customer");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FillInFieldsCustomerId(id);
  }, [id]);

  if (isLoading) return <Loading />;

  if (!formData) return null;

  return (
    <MainTemplate>
      <form>
        <Accordion id="customer-accordion" alwaysOpen>
          <AccordionItem header="Dados do cliente" id={1} show key="cliente">
            <div className="row">
              <div className="col col-6">
                <Select
                  id="customer-resellerId"
                  label="Revenda *"
                  options={toOptionsProps(resellers, true)}
                  value={formData.resellerId.toString()}
                  disabled
                />
              </div>

              <div className="col col-3">
                <Input
                  label="CostumerId"
                  id="customer-customerId"
                  type="text"
                  value={formData.customerId}
                  disabled
                />
              </div>

              <div className="col col-3">
                <SwitchBtn
                  id="customer-isJuridicPerson"
                  label="Pessoa Jurídica?"
                  checked={formData.isJuridicPerson}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-3">
                <Input
                  label="CPF/CNPJ"
                  id="customer-cpfcnpj"
                  placeholder="00.000.000/0000-00"
                  type="text"
                  value={formData.cpfcnpj}
                  disabled
                />
              </div>
              <div className="col col-5">
                <Input
                  label="Nome ou Razão Social"
                  placeholder="Nome completo ou Razão Social"
                  type="text"
                  id="customer-name"
                  value={formData.name}
                  disabled
                />
              </div>
              <div className="col col-4">
                <Input
                  label="Nome Adobe"
                  placeholder="Nome que aparece na Adobe"
                  type="text"
                  id="customer-adobeName"
                  value={formData.adobeName}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-3">
                <Input
                  id="stateRegistration"
                  label="Inscrição Estadual"
                  placeholder="00000000000000"
                  value={formData.stateRegistration}
                />
              </div>
              <div className="col col-3">
                <Input
                  id="municipalRegistration"
                  label="Inscrição Municipal"
                  placeholder="00000000000000"
                  value={formData.municipalRegistration}
                />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem header="Endereço" id={2} show key="endereco">
            <div className="row">
              <div className="col col-2">
                <Input
                  label="CEP"
                  type="text"
                  mask={"99.999-999"}
                  id="address-cep"
                  value={formData.cep}
                  disabled
                />
              </div>
              <div className="col col-4">
                <Input
                  label="Cidade"
                  type="text"
                  id="address-city"
                  value={formData.city}
                  disabled
                />
              </div>
              <div className="col col-2">
                <Select
                  id="address-state"
                  label="UF"
                  options={[
                    { id: "0", text: "Selecione" },
                    { id: "AC", text: "AC" },
                    { id: "AL", text: "AL" },
                    { id: "AP", text: "AP" },
                    { id: "AM", text: "AM" },
                    { id: "BA", text: "BA" },
                    { id: "CE", text: "CE" },
                    { id: "DF", text: "DF" },
                    { id: "ES", text: "ES" },
                    { id: "GO", text: "GO" },
                    { id: "MA", text: "MA" },
                    { id: "MT", text: "MT" },
                    { id: "MS", text: "MS" },
                    { id: "MG", text: "MG" },
                    { id: "PA", text: "PA" },
                    { id: "PB", text: "PB" },
                    { id: "PR", text: "PR" },
                    { id: "PE", text: "PE" },
                    { id: "PI", text: "PI" },
                    { id: "RJ", text: "RJ" },
                    { id: "RN", text: "RN" },
                    { id: "RS", text: "RS" },
                    { id: "RO", text: "RO" },
                    { id: "RR", text: "RR" },
                    { id: "SC", text: "SC" },
                    { id: "SP", text: "SP" },
                    { id: "SE", text: "SE" },
                    { id: "TO", text: "TO" },
                  ]}
                  value={formData.state}
                  disabled
                />
              </div>
              <div className="col col-4">
                <Input
                  label="Logradouro"
                  type="text"
                  id="address-address"
                  value={formData.address}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-5">
                <Input
                  label="Bairro"
                  type="text"
                  id="address-neighborhood"
                  value={formData.neighborhood}
                  disabled
                />
              </div>
              <div className="col col-2">
                <Input
                  label="Número"
                  type="text"
                  id="address-number"
                  value={formData.number}
                  disabled
                />
              </div>
              <div className="col col-5">
                <Input
                  label="Complemento"
                  id="address-adressComplement"
                  type="text"
                  value={formData.adressComplement}
                  disabled
                />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem header="Contato" id={3} key="contato">
            <CustomerContacts formData={formData} disabled></CustomerContacts>
          </AccordionItem>

          <AccordionItem header="Assinatura" id={4} key="assinatura">
            <div className="row">
              <div className="col col-3">
                <Select
                  id="assinatura-segment"
                  label="Segmento"
                  options={[
                    { id: "0", text: "Selecione" },
                    { id: "Commercial", text: "Commercial" },
                    { id: "Education", text: "Education" },
                    { id: "Government", text: "Government" },
                  ]}
                  value={formData.segment}
                  disabled
                />
              </div>
              <div className="col col-2">
                <Select
                  id="assinatura-duration"
                  label="Duração"
                  options={[
                    { id: "0", text: "Selecione" },
                    { id: "TwelveMonths", text: "1 ano" },
                    { id: "TwentyFourMonths", text: "2 anos" },
                    { id: "ThirtySixMonths", text: "3 anos" },
                  ]}
                  value={formData.durationContract}
                  disabled
                />
              </div>
              <div className="col col-2">
                <Input
                  label="Aniversario"
                  id="assinatura-cotermDate"
                  type="text"
                  placeholder="-"
                  value={
                    formData.cotermDate !== "null" &&
                      formData.cotermDate !== null
                      ? new Date(formData.cotermDate).toLocaleDateString("pt-BR")
                      : ""
                  }
                  disabled
                />
              </div>
              <div className="col col-1">
                <Input
                  label="Prorata"
                  id="assinatura-proRata"
                  type="text"
                  value={formData.proRata.toString()}
                  disabled
                />
              </div>
              <div className="col col-2">
                <Input
                  label="Nível licença"
                  placeholder="-"
                  type="text"
                  id="licenseLevel"
                  value={formData.licenseLevel}
                  disabled={true}
                />
              </div>
              <div className="col col-2">
                <Input
                  label="Nível transação"
                  placeholder="-"
                  type="text"
                  id="transactionLevel"
                  value={formData.transactionLevel}
                  disabled={true}
                />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem header="Histórico de Pedidos" id={5} key="history">
            <CustomerOrderHistory
              customerId={formData.id!}
            />
          </AccordionItem>

          <AccordionItem header="Produtos Ativos" id={6} key="product">
            <CustomerActiveProducts
              customerId={formData.id!}
              isActive={!formData.hasPendingRenewal}
            />
          </AccordionItem>
        </Accordion>
      </form>
    </MainTemplate>
  );
};

export default DetailCustomer;