export enum ProfileCode {
    SuperAdminPars = 'SADMINPARS',
    AdminPars = "ADMINPARS",
    FaturamentoPars = "FATPARS",
    ComprarPars = "COMPRASPARS",
    VendasPars = "VENDASPARS",
    AdminRevenda = "ADMINREVENDA",
    ComprasRevenda = "COMPRASREVENDA",
    ConsultaRevenda = "CONSULTAREVENDA",
    VendasRevenda  ="VENDASREVENDA"
}