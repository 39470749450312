import React, { useCallback, useState } from 'react';
import { FilterRenewList } from '../../../models/Renew';
import { MainTemplate } from '../../../components/templates/MainTemplate';
import FilterListRenew, { FormFilterListRenew } from './FilterListRenew';
import TableListRenew from './TableListRenew';

const RmaRenew: React.FC = () => {

  const [filters, setFilters] = useState<FilterRenewList>({
    page: 0,
    size: 10,
    resellerId: '',
    customerId: '',
    customerIdAdobe: '',
    days: '90'
  });

  const handleSearch = useCallback((form: FormFilterListRenew): void => {
    setFilters({...form, page: 0, size: 10});
  }, []);

  return (
    <MainTemplate>
      <FilterListRenew onSearch={handleSearch} />
      <TableListRenew filters={filters} />
    </MainTemplate>
  );
};

export default RmaRenew;