import { MainTemplate } from "../../components/templates/MainTemplate";
import { Input } from "../../components/atoms/Input";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-bs5";
import React, { useEffect, useState } from "react";
import { Card } from "../../components/mols/Card";
import { toastAndRedirect, useToast } from "../../components/atoms/Toast";
import { Accordion } from "../../components/mols/Accordion";
import { AccordionItem } from "../../components/atoms/AccordionItem";
import { Select } from "../../components/atoms/Select";
import { SwitchBtn } from "../../components/atoms/SwitchBtn";
import { Icon } from "../../components/atoms/Icon";
import {
  editCustomer,
  getByCnpj,
  getCustomerById,
} from "../../services/api/CustomerService";
import { useForm } from "../../hooks/formHook";
import { EditCustomerForm, toEditCustomerDto } from "./models/CustomerForm";
import { CustomerContacts } from "./components/CustomerContacts";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/orgs/Loading";
import { LoadingSearch } from "../../components/orgs/LoadingSearch";
import { duration } from "./customerEnum/Duration";
import { segments } from "./customerEnum/Segments";
import { states } from "./customerEnum/State";
import { CustomerOrderHistory } from "./components/CustomerOrderHistory";
import { CustomerActiveProducts } from "./components/CustomerActiveProducts";
import {
  AddressForm,
  useValidateAddressLength,
} from "../../hooks/useValidateAddressLength";

import { ProfileCode } from "../../models/enums/ProfileCode";

DataTable.use(DT);

const EditCustomer: React.FC = () => {
  const { id: idStr } = useParams();
  const id = BigInt(idStr ?? 0);
  const toast = useToast();
  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;

  const hasReadResellerProfile = profile.profileCode == ProfileCode.ConsultaRevenda;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [formData, setFormData] = useState<EditCustomerForm>({
    id: 0,
    customerId: "",
    cpfcnpj: "",
    name: "",
    adobeName: "",
    isJuridicPerson: true,
    country: "",
    cep: "",
    city: "",
    state: "",
    address: "",
    neighborhood: "",
    number: "",
    adressComplement: "",
    isThreeYears: false,
    segment: "",
    adobeStatus: "",
    durationContract: "",
    typeClassProduct: "",
    resellerId: "",
    resellerName: "",
    resellerAdobeId: "",
    cotermDate: "",
    dateCreated: "",
    proRata: "",
    custId: "",
    ResId: "",
    licenseLevel: "",
    transactionLevel: "",
    signingResponsibleName: "",
    signingResponsibleTypePhone: "",
    signingResponsibleNumber: "",
    signingResponsibleEmail: "",
    financialResponsibleName: "",
    financialResponsibleTypePhone: "",
    financialResponsibleNumber: "",
    financialResponsibleEmail: "",
    purchasingResponsibleName: "",
    purchasingResponsibleTypePhone: "",
    purchasingResponsibleNumber: "",
    purchasingResponsibleEmail: "",
    products: [],
    hasPendingRenewal: false,
    stateRegistration: "",
    municipalRegistration: ""
  });

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isFocused, setIsFocused] = useState(false);
  const { validateAddressLength } = useValidateAddressLength();

  const { handleInputChange, handleError, handleNumberInputChange } =
    useForm<EditCustomerForm>({
      setFormData,
    });

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    handleInputChange(e);
    const { id } = e.target;
    const newValue = (e.target as HTMLSelectElement).selectedOptions[0].id;
    setFormData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));
  };

  const FillInFieldsCustomerId = (id: BigInt) => {
    setIsLoading(true);
    getCustomerById(id)
      .then((response) => {
        setFormData({
          ...formData,
          products: (response.products || []).map((product: any) => ({
            id: product.id,
            customerId: product.customerId,
            productId: product.productId,
            productName: product.productName || "",
            sku: product.sku || "",
            subscriptionId: product.subscriptionId || "",
            usedQuantity: product.usedQuantity || 0,
            currentQuantity: product.currentQuantity || 0,
            autoRenewal: product.autoRenewal || false,
            quantityRenewal: product.quantityRenewal || 0,
          })),
          id: Number(id),
          customerId: response.customerId,
          cpfcnpj: response.cpfcnpj,
          name: response.name,
          adobeName: response.adobeName,
          isJuridicPerson: response.isJuridicPerson,
          country: response.country,
          cep: response.cep,
          city: response.city,
          state: response.state,
          address: response.address,
          neighborhood: response.neighborhood,
          number: response.number,
          adressComplement: response.adressComplement,
          isThreeYears: response.isThreeYears,
          adobeStatus: response.adobeStatus,
          durationContract: response.durationContract,
          typeClassProduct: response.typeClassProduct,
          resellerId: String(response.resellerId),
          resellerName: `${response.resellerName + " - " + response.resellerAdobeId}`,
          proRata: String(response.proRata),
          segment: response.marketSegment,
          cotermDate: String(response.cotermDate) || "",
          dateCreated: String(response.dateCreated) || "",
          ResId: String(response.resellerId),
          custId: response.customerId,
          licenseLevel: response.licenseLevel || "",
          transactionLevel: response.transactionLevel || "",

          signingResponsibleName:
            response.customerContacts?.find(
              (item) => item.typeContact === "Signature"
            )?.name || "",
          signingResponsibleTypePhone:
            response.customerContacts?.find(
              (item) => item.typeContact === "Signature"
            )?.typePhone || "",
          signingResponsibleNumber:
            response.customerContacts?.find(
              (item) => item.typeContact === "Signature"
            )?.phoneNumber || "",
          signingResponsibleEmail:
            response.customerContacts?.find(
              (item) => item.typeContact === "Signature"
            )?.email || "",
          financialResponsibleName:
            response.customerContacts?.find(
              (item) => item.typeContact === "Financial"
            )?.name || "",
          financialResponsibleTypePhone:
            response.customerContacts?.find(
              (item) => item.typeContact === "Financial"
            )?.typePhone || "",
          financialResponsibleNumber:
            response.customerContacts?.find(
              (item) => item.typeContact === "Financial"
            )?.phoneNumber || "",
          financialResponsibleEmail:
            response.customerContacts?.find(
              (item) => item.typeContact === "Financial"
            )?.email || "",
          purchasingResponsibleName:
            response.customerContacts?.find(
              (item) => item.typeContact === "Purchasing"
            )?.name || "",
          purchasingResponsibleTypePhone:
            response.customerContacts?.find(
              (item) => item.typeContact === "Purchasing"
            )?.typePhone || "",
          purchasingResponsibleNumber:
            response.customerContacts?.find(
              (item) => item.typeContact === "Purchasing"
            )?.phoneNumber || "",
          purchasingResponsibleEmail:
            response.customerContacts?.find(
              (item) => item.typeContact === "Purchasing"
            )?.email || "",
          hasPendingRenewal: response.hasPendingRenewal,
          municipalRegistration: response.municipalRegistration,
          stateRegistration: response.stateRegistration
        });
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error, toast, "buscar informação do customer");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FillInFieldsCustomerId(id);
  }, [id]);

  const FillInFieldsCnpj = async () => {
    try {
      setIsLoadingSearch(true);
      const response = await getByCnpj(formData.cpfcnpj);

      setFormData((form) => ({
        ...form,
        name: response.nome,
        country: "Brasil",
        cep: response.cep,
        city: response.municipio,
        state: response.uf,
        address: response.logradouro,
        neighborhood: response.bairro,
        number: response.numero,
        adressComplement: response.complemento,
      }));
    } catch (error) {
      handleError(error, toast, "buscar informação do CNPJ");
    } finally {
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (isFocused && formData.cpfcnpj.length > 13 && formData.isJuridicPerson) {
      FillInFieldsCnpj();
    }
  }, [formData.cpfcnpj]);

  const setPhoneLength = () => {
    if (
      formData?.signingResponsibleTypePhone === "Telephone" &&
      formData?.signingResponsibleNumber.length > 10
    ) {
      setFormData({
        ...formData,
        signingResponsibleNumber: formData.signingResponsibleNumber.slice(
          0,
          10
        ),
      });
    } else if (
      formData?.financialResponsibleTypePhone === "Telephone" &&
      formData?.financialResponsibleNumber.length > 10
    ) {
      setFormData({
        ...formData,
        financialResponsibleNumber: formData.financialResponsibleNumber.slice(
          0,
          10
        ),
      });
    } else if (
      formData?.purchasingResponsibleTypePhone === "Telephone" &&
      formData?.purchasingResponsibleNumber.length > 10
    ) {
      setFormData({
        ...formData,
        purchasingResponsibleNumber: formData.purchasingResponsibleNumber.slice(
          0,
          10
        ),
      });
    }
  };

  useEffect(() => {
    setPhoneLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.signingResponsibleTypePhone, formData.financialResponsibleTypePhone, formData.purchasingResponsibleTypePhone]);

  const submitForm = async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!validateForm()) return;

    try {
      const customer = toEditCustomerDto(formData);

      setIsLoadingSave(true);

      await editCustomer(customer);
      toastAndRedirect(
        toast,
        "success",
        "Customer Editado",
        "/customers",
        "Você será redirecionado para a tela de customers em 3s"
      );
    } catch (ex) {
      handleError(ex, toast, "criar customers");
    } finally {
      setIsLoadingSave(false);
    }
  };

  const validateForm = (): boolean => {
    const requiredFields = [
      { id: "resellerId", label: "Revenda" },
      { id: "cpfcnpj", label: "CNPJ" },
      { id: "name", label: "Nome ou Razão Social" },
      { id: "adobeName", label: "Nome Adobe" },
      { id: "cep", label: "CEP" },
      { id: "city", label: "Cidade" },
      { id: "state", label: "UF" },
      { id: "address", label: "Logradouro" },
      { id: "neighborhood", label: "Bairro" },
      { id: "number", label: "Número" },
      {
        id: "signingResponsibleName",
        label: "Nome do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleTypePhone",
        label: "Tipo de Telefone do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleNumber",
        label: "Telefone do Responsável pela Assinatura",
      },
      {
        id: "signingResponsibleEmail",
        label: "Email do Responsável pela Assinatura",
      },
      { id: "segment", label: "Segmento" },
      { id: "durationContract", label: "Duração" },
      { id: "typeClassProduct", label: "Tipo" },
    ];

    const newErrors: Record<string, boolean> = {};
    let isValid = true;

    for (const field of requiredFields) {
      const fieldValue = formData[field.id as keyof EditCustomerForm];
      if (!fieldValue && field.id !== "customerId") {
        newErrors[field.id] = true;
        isValid = false;
        toast.fire({
          icon: "warning",
          title: `O campo ${field.label} é obrigatório.`,
        });
        break;
      }

      if (field.id.includes("ResponsibleName") && fieldValue) {
        if (typeof fieldValue === "string") {
          const nameParts = fieldValue.trim().split(" ");
          if (nameParts.length < 2) {
            newErrors[field.id] = true;
            isValid = false;
            toast.fire({
              icon: "warning",
              title: `O campo ${field.label} deve conter nome e sobrenome.`,
            });
          }
        }
      }
    }

    const addressForm: AddressForm = {
      address: formData.address,
      number: formData.number,
      neighborhood: formData.neighborhood,
      addressComplement: formData.adressComplement,
    };

    if (!validateAddressLength(addressForm)) {
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSwitchChange = (checked: boolean) => {
    setFormData({ ...formData, isJuridicPerson: checked });
  };

  if (isLoading) return <Loading />;

  return (
    <MainTemplate>
      <Card>
        {isLoadingSearch ? <LoadingSearch /> : <b></b>}
        <form onSubmit={submitForm}>
          <Accordion id="customer-accordion" alwaysOpen>
            <AccordionItem header="Dados do cliente" id={1} show key="cliente">
              <div className="row">
                <div className="col col-6">
                  <Input
                    label="Revenda"
                    id="resellerName"
                    placeholder=""
                    type="text"
                    disabled={true}
                    onChange={handleInputChange}
                    value={formData.resellerName}
                  />
                </div>

                <div className="col col-3">
                  <Input
                    label="Customer ID"
                    id="customerId"
                    placeholder=""
                    type="text"
                    disabled={true}
                    onChange={handleInputChange}
                    value={formData.customerId}
                  />
                </div>

                <div className="col col-3">
                  <SwitchBtn
                    id="isJuridicPerson"
                    label="Pessoa Jurídica?"
                    checked={formData.isJuridicPerson}
                    onChange={handleSwitchChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-3">
                  <Input
                    label={formData.isJuridicPerson ? "CNPJ *" : "CPF *"}
                    id="cpfcnpj"
                    placeholder={
                      formData.isJuridicPerson
                        ? "00.000.000/0000-00"
                        : "000.000.000-00"
                    }
                    type="text"
                    mask={
                      formData.isJuridicPerson
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    onChange={handleNumberInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={formData.cpfcnpj}
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Nome ou Razão Social *"
                    placeholder="Nome completo ou Razão Social"
                    type="text"
                    id="name"
                    onChange={handleInputChange}
                    value={formData.name}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Nome Adobe *"
                    placeholder="Nome que aparece na Adobe"
                    type="text"
                    id="adobeName"
                    onChange={handleInputChange}
                    value={formData.adobeName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-3">
                  <Input
                    id="stateRegistration"
                    label="Inscrição Estadual"
                    placeholder="00000000000000"
                    onChange={handleNumberInputChange}
                    value={formData.stateRegistration}
                  />
                </div>
                <div className="col col-3">
                  <Input
                    id="municipalRegistration"
                    label="Inscrição Municipal"
                    placeholder="00000000000000"
                    onChange={handleNumberInputChange}
                    value={formData.municipalRegistration}
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Endereço" id={2} show key="endereco">
              <div className="row">
                <div className="col col-2">
                  <Input
                    label="CEP *"
                    id="cep"
                    placeholder="00.000-000"
                    type="text"
                    mask={"99.999-999"}
                    onChange={handleInputChange}
                    value={formData.cep}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Cidade *"
                    placeholder=""
                    type="text"
                    id="city"
                    onChange={handleInputChange}
                    value={formData.city}
                  />
                </div>
                <div className="col col-2">
                  <Select
                    id="state"
                    label="UF *"
                    options={states}
                    onChange={handleSelectChange}
                    value={formData.state}
                  />
                </div>
                <div className="col col-4">
                  <Input
                    label="Logradouro *"
                    placeholder=""
                    type="text"
                    id="address"
                    onChange={handleInputChange}
                    value={formData.address}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-5">
                  <Input
                    label="Bairro *"
                    placeholder=""
                    type="text"
                    id="neighborhood"
                    onChange={handleInputChange}
                    value={formData.neighborhood}
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Número *"
                    placeholder=""
                    type="text"
                    id="number"
                    onChange={handleInputChange}
                    value={formData.number}
                  />
                </div>
                <div className="col col-5">
                  <Input
                    label="Complemento"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    id="adressComplement"
                    value={formData.adressComplement}
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Contato" id={3} key="contato">
              <CustomerContacts
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleNumberInputChange={handleNumberInputChange}
                formData={formData}
              ></CustomerContacts>
            </AccordionItem>

            <AccordionItem header="Assinatura" id={4} key="assinatura">
              <div className="row">
                <div className="col col-3">
                  <Select
                    id="segment"
                    label="Segmento *"
                    options={segments}
                    onChange={handleSelectChange}
                    value={formData.segment}
                  />
                </div>
                <div className="col col-2">
                  <Select
                    id="durationContract"
                    label="Duração *"
                    options={duration}
                    onChange={handleSelectChange}
                    value={formData.durationContract}
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Aniversario"
                    placeholder="-"
                    type="text"
                    onChange={handleInputChange}
                    id="cotermDate"
                    value={
                      formData.cotermDate !== "null" &&
                        formData.cotermDate !== null
                        ? new Date(formData.cotermDate).toLocaleDateString("pt-BR")
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="col col-1">
                  <Input
                    label="Prorata"
                    placeholder=""
                    type="text"
                    onChange={handleInputChange}
                    id="proRata"
                    value={formData.proRata}
                    disabled={true}
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Nível licença"
                    placeholder="-"
                    type="text"
                    id="licenseLevel"
                    value={formData.licenseLevel}
                    disabled={true}
                  />
                </div>
                <div className="col col-2">
                  <Input
                    label="Nível transação"
                    placeholder="-"
                    type="text"
                    id="transactionLevel"
                    value={formData.transactionLevel}
                    disabled={true}
                  />
                </div>
              </div>
            </AccordionItem>

            <AccordionItem header="Histórico de Pedidos" id={5} key="history">
              <CustomerOrderHistory
                customerId={formData.id}
              />
            </AccordionItem>

            <AccordionItem header="Produtos Ativos" id={6} key="product">
              <CustomerActiveProducts
                customerId={formData.id}
                isActive={!formData.hasPendingRenewal}
                onUpdateProduct={(updatedProduct) => {
                  setFormData((prevData) => {
                    const updatedProducts = prevData.products.map((product) =>
                      product.id === updatedProduct.id
                        ? updatedProduct
                        : product
                    );
                    return { ...prevData, products: updatedProducts };
                  });
                }}
              />
            </AccordionItem>
          </Accordion>

          {!hasReadResellerProfile && (
            <button
              className="btn btn-primary btn-lg"
              style={{ width: "6em", height: "2em" }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <div>
                  <Icon className="fa fa-fw fa-save" />
                </div>
              )}
            </button>
          )}
        </form>
      </Card>
    </MainTemplate>
  );
};

export { EditCustomer };
