import { useState, useEffect } from "react";
import { Card } from "../../../components/mols/Card";
import { Input } from "../../../components/atoms/Input";
import { Select } from "../../../components/atoms/Select";
import { OrderStatusResponse } from "./OrderStatus";
import { paymentConditionMap } from "../../../models/enums/PaymentCondition";
import { billingForMap } from "../../../models/enums/BillingFor";
import { orderType } from "../../../models/enums/orderType";
import { orderStatusMapOptions, toOrderStatusText } from "../../../models/enums/orderStatus";

export interface OrderStatusFormProps {
  data: OrderStatusResponse;
}

export const OrderStatusHeader: React.FC<OrderStatusFormProps> = ({ data }) => {
  const [orderStatusForm, setOrderStatusForm] =
    useState<OrderStatusResponse>(data);

  useEffect(() => {
    setOrderStatusForm(data);
  }, [data]);

  return (
    <Card className="mb-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h1 className="h3">Detalhes do Pedido</h1>
        <div className="row">
          <div className="col col-md-2 col-6">
            <Input
              label="Data"
              type="date"
              value={orderStatusForm.date || ""}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Input
              label="Revenda"
              value={data.reseller?.toString() || ""}
              disabled
            />
          </div>
          <div className="col col-md-4 col-6">
            <Input
              label="Cliente"
              value={data.customer?.toString() || ""}
              disabled
            />
          </div>
          <div className="col col-md-1 col-6">
            <Input
              label="Pro Rata"
              type="number"
              value={orderStatusForm.proRata?.toString() || ""}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Aniversário"
              type="date"
              value={orderStatusForm.cotermDate || ""}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Select
              label="Tipo"
              id="type"
              options={[
                { id: orderType.New.toString(), text: "Novo Pedido" },
                { id: orderType.Renewal.toString(), text: "Renovação" },
              ]}
              value={orderStatusForm.type?.toString()}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Nº MP"
              type="text"
              placeholder="S/N"
              value={orderStatusForm.orderId.toString()}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Pedido Cliente"
              type="text"
              placeholder="S/N"
              value={orderStatusForm.customerOrder}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="Pedido Revenda"
              type="text"
              placeholder="S/N"
              value={orderStatusForm.resellerOrder}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Input
              label="No Adobe"
              type="text"
              placeholder="S/N"
              value={orderStatusForm.adobeOrderId}
              disabled
            />
          </div>
          <div className="col col-md-2 col-6">
            <Select
              label="Faturar para"
              options={Object.values(billingForMap)}
              value={orderStatusForm.billingFor ?? ""}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Select
              label="Forma de pagamento"
              options={[{ id: "Boleto", text: "Boleto" }]}
              value={orderStatusForm.paymentCondition || "Boleto"}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Select
              label="Condições de faturamento"
              options={Object.values(paymentConditionMap)}
              value={orderStatusForm.paymentCondition?.toString()}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Input
              label="Responsável Pedido"
              type="text"
              placeholder="Responsável"
              value={orderStatusForm.responsible}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
          <Select
              label="Status"
              options={orderStatusMapOptions()}
              value={orderStatusForm.status?.toString()}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Input
              label="CNPJ Revenda"
              type="text"
              placeholder=""
              mask={orderStatusForm.resellerCnpj
                ? "99.999.999/9999-99"
                : ""
              }
              value={orderStatusForm.resellerCnpj}
              disabled
            />
          </div>
          <div className="col col-md-3 col-6">
            <Input
              label={
                orderStatusForm.customerCnpj
                  ? String(orderStatusForm.customerCnpj).length > 11
                    ? "CNPJ Cliente"
                    : "CPF Cliente"
                  : ""
              }
              type="text"
              placeholder=""
              mask={orderStatusForm.customerCnpj
                ? String(orderStatusForm.customerCnpj).length > 11
                  ? "99.999.999/9999-99"
                  : "999.999.999-99"
                : ""
              }
              value={orderStatusForm.customerCnpj}
              disabled
            />
          </div>
        </div>
      </form>
    </Card>
  );
};
