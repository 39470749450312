import React, { useCallback, useEffect, useRef } from "react";
import { Card } from "../../../components/mols/Card";
import { useNavigate } from "react-router-dom";
import { getRmaOrders } from "../../../services/api/RmaService";
import { FilterRmaList, RmaOrders } from "../../../models/Rma";
import ptbr from "../../../assets/lib/js/dataTables/pt-br.json";
import DataTable, { DataTableRef } from "datatables.net-react";
import DT from "datatables.net-dt";
import { useToast } from "../../../components/atoms/Toast";
import $ from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";
import JSZip from "jszip";
import moment from "moment";
import { toOrderStatusText } from "../../../models/enums/orderStatus";
// @ts-ignore
window.JSZip = JSZip;

DataTable.use(DT);
interface TableListRmaProps {
  filters: FilterRmaList;
}

const TableListRma: React.FC<TableListRmaProps> = ({ filters }) => {
  const Toast = useToast();
  const navigate = useNavigate();
  const dataTableRef = useRef<DataTableRef>(null);

  const handleDetail = useCallback(
    (item: RmaOrders) => {
      navigate(`detail/${item.orderId}`, {
        state: {
          status: item.status,
        },
      });
    },
    [navigate]
  );

  const loadTableRef = useRef<(data: any, callback: any) => void>();
  const loadTable = useCallback(
    async (data: any, callback: any) => {
      const { start, length, draw } = data;
      const currentPage = Math.floor(start / length);

      const newFilters = { ...filters, page: currentPage, size: length };

      try {
        const result = await getRmaOrders(newFilters);

        callback({
          draw: draw,
          recordsTotal: result.total,
          recordsFiltered: result.total,
          data: result.content,
        });
      } catch (error) {
        if (error instanceof Error) {
          Toast.fire({
            icon: "error",
            title: error.message,
          });
        }

        callback({
          draw: draw,
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      }
    },
    [Toast, filters]
  );

  useEffect(() => {
    loadTableRef.current = loadTable;
  }, [filters, loadTable]);

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.dt()?.ajax.reload();
    }
  }, [filters, handleDetail]);

  return (
    <Card>
      <DataTable
        ref={dataTableRef}
        className="table table-sm table-striped"
        options={{
          dom: "B<'row my-2'<'col-6'l><'col-6'f>>rt<'row my-2'<'col-12 d-flex justify-content-center'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: '<i class="fa fa-file-excel"></i>',
              filename: "Revendas",
              className: "btn btn-success",
              exportOptions: {
                columns: ":visible",
              },
            },
            {
              extend: "csvHtml5",
              text: '<i class="fa fa-file-csv"></i>',
              filename: "Revendas",
              className: "btn btn-info",
              exportOptions: {
                columns: ":visible",
              },
              init: function (api, node) {
                $(node).css("margin-left", "5px");
              },
            },
          ],
          language: ptbr,
          lengthMenu: [
            [10, 20, 50, 100, 500],
            [10, 20, 50, 100, 500],
          ],
          ordering: false,
          serverSide: true,
          processing: true,
          scrollY: "calc(100vh - 87px - 13em)",
          scrollCollapse: true,
          ajax: (data: any, callback: any) => {
            if (loadTableRef.current) loadTableRef.current(data, callback);
          },
        }}
        columns={[
          { data: "customerId", width: "calc(6em + 8px)" },
          { data: "reseller" },
          { data: "customer" },
          { data: "orderId", width: "5em", className: "text-center" },
          {
            data: "orderDate",
            width: "5em",
            render: (data) => moment(data).format("DD/MM/YYYY"),
          },
          { data: "pedAdobe", width: "6em" },
          {
            data: "rmaDeadlineFormated",
            width: "7em",
            className: "text-center",
          },
          {
            data: "rmaCode",
            width: "6em",
            className: "text-center",
            render: (data) => {
              return data || "-";
            },
          },
          {
            data: "status",
            render: (status) => {
              if (status !== "Draft") return toOrderStatusText(status);
              else return "Disponível";
            },
          },
          {
            render: (_, __, row: any) => {
              let btnText = row.status === "Draft" ? "Solicitar" : "Detalhes";

              return `
                <a href="/rma/detail/${row.orderId}"
                class="btn btn-sm btn-primary">
                  ${btnText}
                </a>`;
            },
          },
        ]}
      >
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Revenda</th>
            <th>Cliente</th>
            <th>Nº MP</th>
            <th>Dt. Pedido</th>
            <th>Ped. Adobe</th>
            <th>Prazo de RMA</th>
            <th>Cód. RMA</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
      </DataTable>
    </Card>
  );
};

export default TableListRma;
