import { useState } from "react";
import { ResellerForm } from "../models/ResellerForm";
import { phoneTypes } from "../../../models/enums/PhoneTypes";
import { accountLevel } from "../../../models/enums/AccountLevel";
import { useForm } from "../../../hooks/formHook";

export const useResellerForm = () => {
  const [formData, setFormData] = useState<ResellerForm>({
    cnpj: "",
    stateRegistration: "",
    municipalRegistration: "",
    corporateName: "",
    tradeName: "",
    activity: {
      businessSegment: false,
      educationSegment: false,
      governmentSegment: false,
    },
    membership: "",
    zipCode: "",
    city: "",
    state: "",
    street: "",
    neighborhood: "",
    number: "",
    addressComplement: "",
    contactName: "",
    phoneType: phoneTypes[0].id,
    contactPhone: "",
    contactEmail: "",
    accountLevel: accountLevel[0].id,
    expirationDate: null,
  });

  const { handleInputChange, handleCheckboxChange, handleError, handleNumberInputChange } =
    useForm<ResellerForm>({ setFormData });

  return {
    formData,
    setFormData,
    handleInputChange,
    handleCheckboxChange,
    handleError,
    handleNumberInputChange
  };
};
