import { MainTemplate } from "../../../components/templates/MainTemplate";
import React, { useState, useRef, useCallback } from "react";
import { Card } from "../../../components/mols/Card";
import { Icon } from "../../../components/atoms/Icon";
import { Reseller } from "../../../models/Reseller";
import { autocompleteResellers } from "../../../services/api/ResellerService";
import { CustomerListFilters } from "./CustomerListFilters";
import {
  CustomerFilters,
  newCustomerFilters,
} from "../../../models/CustomerFilters";
import { CustomerListTable } from "./CustomerListTable";
import { getAuthUser } from "../../../utils/authUtils";
import { useForm } from "../../../hooks/formHook";
import { ProfileCode } from "../../../models/enums/ProfileCode";

const CustomerList: React.FC = () => {
  const tableRef = useRef<any>(null);
  const isReseller = getAuthUser()?.profile.isReseller ?? false;

  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;
  const hasReadResellerProfile = profile.profileCode == ProfileCode.ConsultaRevenda;

  const [filters, setFilters] = useState<CustomerFilters>(newCustomerFilters());
  const [appliedFilters, setAppliedFilters] = useState<CustomerFilters>({});

  const { handleInputChange } = useForm({ setFormData: setFilters });

  const loadResellers = useCallback(
    async (search: string): Promise<Reseller[]> => {
      return await autocompleteResellers(search);
    },
    []
  );

  const handleFilter = () => {
    setAppliedFilters({ ...filters });
  };

  return (
    <MainTemplate>
      <Card className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h3">Clientes</h1>
          {!hasReadResellerProfile && (
            <div>
              <a href="/customer/add" className="btn btn-primary">
                <Icon className="fa fa-fw fa-plus" /> Novo cliente
              </a>
            </div>
          )}
        </div>

        <CustomerListFilters
          filters={filters}
          isReseller={isReseller}
          loadResellers={loadResellers}
          setFilters={setFilters}
          onFilter={handleFilter}
          onInputChange={handleInputChange}
        />
      </Card>

      <Card>
        <CustomerListTable tableRef={tableRef} filters={appliedFilters} />
      </Card>
    </MainTemplate>
  );
};

export { CustomerList };
