import React, { useMemo } from "react";
import { formatMoneyBRL, formatMoneyUSD } from "../../../utils/currency";
import { OrderItem } from "../../../models/OrderItem";

interface TableDetailRmaItemProps {
  index: number;
  data: OrderItem;
  isCanceled: boolean;
  allowCancel: boolean;
  onChecked(item: OrderItem, checked: boolean): void;
}

const TableDetailRmaItem: React.FC<TableDetailRmaItemProps> = ({
  index,
  data,
  isCanceled,
  allowCancel = false,
  onChecked,
}) => {
  const bgClass = isCanceled ? "error-bg" : "";

  return (
    <tr>
      <td className={`${bgClass} text-center`}>{index}</td>
      <td className={bgClass}>{data.product.license}</td>
      <td className={`${bgClass} text-center`}>{data.product.sku}</td>
      <td className={`${bgClass} text-center`}>{data.quantity}</td>
      <td className={`${bgClass} border-start text-center`}>
        {formatMoneyUSD(data.resellerPriceUSD)}
      </td>
      <td className={`${bgClass} border-end text-center`}>
        {formatMoneyUSD(data.resellerPriceUSD * data.quantity)}
      </td>
      <td className={`${bgClass} border-start text-center`}>
        {formatMoneyUSD(data.invoicePriceUSD)}
      </td>
      <td className={`${bgClass} border-end text-center`}>
        {formatMoneyUSD(data.invoicePriceUSD)}
      </td>
      <td className={`${bgClass} border-start text-center`}>
        {formatMoneyBRL(data.invoicePriceBRL)}
      </td>
      <td className={`${bgClass} border-end text-center`}>
        {formatMoneyBRL(data.totalInvoicePriceBRL)}
      </td>
      <td className={`${bgClass} text-center`}>
        {allowCancel && !isCanceled ? (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => onChecked(data, e.target.checked)}
            />
          </div>
        ) : (
          <>&nbsp;</>
        )}
      </td>
    </tr>
  );
};

export default TableDetailRmaItem;
