import React from "react";
import { useErrorHandler } from "./useErrorHandler";

interface UseFormProps<T> {
  setFormData: React.Dispatch<React.SetStateAction<T>>;
}

export const useForm = <T extends object>({ setFormData }: UseFormProps<T>) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value, type } = e.target;
    const newValue =
      type === "select-one"
        ? (e.target as HTMLSelectElement).selectedOptions[0].id
        : value;

    setFormData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));
  };

  const handleCheckboxChange = (updatedActivity: { [key: string]: boolean }) => {
    setFormData((prevData) => ({
      ...prevData,
      activity: updatedActivity,
    }));
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const newValue = value.replace(/\D/g, "").trim();
    setFormData((prevData) => ({ ...prevData, [id]: newValue, }));
  };

  const { handleError } = useErrorHandler();

  return { handleCheckboxChange, handleInputChange, handleError, handleNumberInputChange };
};
