import React, { useEffect, useState } from "react";
import { Icon } from "../atoms/Icon";
import { useAuth } from "../../context/AuthContext";
import { getAuthUser } from "../../utils/authUtils";
import { AuthUser } from "../../models/AuthUser";
import { Order } from "../../models/Order";
import { storage } from "../../utils/storage";

interface NavbarProps {
  currentOrder?: Order;
  toggleSidebar: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({
  currentOrder,
  toggleSidebar,
}) => {
  const { logout } = useAuth();
  const [user] = useState<AuthUser | null>(getAuthUser());
  const [order, setOrder] = useState<Order | null>(
    currentOrder ?? storage.get<Order>("currentOrder")
  );

  useEffect(() => {
    setOrder(currentOrder ?? order);
  }, [currentOrder, order]);

  return (
    <nav className="navbar primary-bg d-flex justify-content-between">
      <button className="btn text-light" onClick={toggleSidebar}>
        <Icon className="fa fa-fw fa-bars icon-large" />
      </button>

      <div className="d-flex align-items-center justify-content-between gap-5">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pars.com.br/suporte-adobe/"
          className="text-white cart-button"
        >
          <i className="fa fa-headset"></i> SUPORTE
        </a>
        {order && (
          <a href="/order/cart" className="text-white cart-button">
            <Icon className="fa fa-fw fa-shopping-cart" />
            <small>{order.items.length}</small>
          </a>
        )}
        <div className="dropdown dropstart">
          <button
            className="btn text-light dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Icon className="fa fa-fw fa-user" /> {user?.name}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <button className="dropdown-item" onClick={logout}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
