import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { formatMoneyBRL, formatMoneyUSD } from "../../../utils/currency";
import { useToast } from "../../../components/atoms/Toast";
import { cancelOrderItemsRma } from "../../../services/api/RmaService";
import { Card } from "../../../components/mols/Card";
import TableDetailRmaItem from "./TableDetailRmaItem";
import { ModalCancelItemRma } from "./ModalCancelItemRma";
import { OrderItem } from "../../../models/OrderItem";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

export interface TableDetailRmaData {
  orderId: number;
  resellerTotalPriceUSD: number;
  resellerTotalPriceBRL: number;
  invoiceTotalPriceUSD: number;
  invoiceTotalPriceBRL: number;
  items: OrderItem[];
}
interface TableDetailRmaProps {
  data: TableDetailRmaData | null;
}

const TableDetailRma: React.FC<TableDetailRmaProps> = ({ data }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const [selItems, setSelItems] = useState<OrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);

  const [totals, setTotals] = useState({
    resellerTotalPriceUSD: 0,
    invoiceTotalPriceUSD: 0,
    invoiceTotalPriceBRL: 0,
  });

  const handleSelectItem = useCallback(
    (item: OrderItem, checked: boolean) => {
      if (checked) setSelItems([...selItems, item]);
      else setSelItems(selItems.filter((x) => x.id !== item.id));
    },
    [selItems]
  );

  const handleOpenModal = useCallback(() => {
    if (!selItems.length) {
      toast.fire({
        icon: "warning",
        title:
          "Para cancelar os itens é necessário informar pelo menos 1 item.",
      });
      return;
    }

    setConfirmModalOpened(true);
  }, [toast, selItems.length]);

  const handleCancelItems = useCallback(async (): Promise<void> => {
    if (!data) return;

    try {
      setIsLoading(true);

      await cancelOrderItemsRma({
        orderId: data?.orderId,
        items: selItems.map((item) => ({
          orderItemId: item.id,
        })),
      });

      toast.fire({
        icon: "success",
        title: "Itens cancelado com sucesso!",
      });

      navigate("/rma");
    } catch (error) {
      handleError(error, toast);
    } finally {
      setIsLoading(false);
      setConfirmModalOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navigate, selItems]);

  const allowCancel = useMemo(() => {
    if (!data) return false;

    const activeItemCount = data.items.filter(
      (item) => item.status === "Active"
    ).length;

    return activeItemCount > 0;
  }, [data]);

  useEffect(() => {
    if (!data) return;

    const newTotals = {
      resellerTotalPriceUSD: 0,
      invoiceTotalPriceUSD: 0,
      invoiceTotalPriceBRL: 0,
    };

    data.items.forEach((item) => {
      newTotals.resellerTotalPriceUSD += item.resellerPriceUSD * item.quantity;
      newTotals.invoiceTotalPriceUSD += item.totalInvoicePriceUSD;
      newTotals.invoiceTotalPriceBRL += item.totalInvoicePriceBRL;
    });

    setTotals(newTotals);
  }, [data]);

  if (!data) return null;

  return (
    <Card>
      <h1 className="h3">Produtos</h1>

      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th rowSpan={2} style={{ textAlign: "center" }}>
              #
            </th>
            <th rowSpan={2}>Produto</th>
            <th rowSpan={2} style={{ textAlign: "center" }}>
              SKU
            </th>
            <th rowSpan={2} style={{ textAlign: "center" }}>
              Quantidade
            </th>
            <th colSpan={2} className="text-center border-start border-end">
              Revenda
            </th>
            <th colSpan={4} className="text-center border-start border-end">
              Faturamento
            </th>
            <th rowSpan={2}>&nbsp;</th>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th className="border-start">Unit. Tab U$</th>
            <th className="border-end">Total U$</th>
            <th className="border-start">Unit. Tab U$</th>
            <th className="border-end">Total U$</th>
            <th className="border-start">Unit. Tab R$</th>
            <th className="border-end">Total R$</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => (
            <TableDetailRmaItem
              key={index}
              index={index + 1}
              data={item}
              onChecked={handleSelectItem}
              isCanceled={item.status === "Canceled"}
              allowCancel={allowCancel}
            />
          ))}
        </tbody>
        <tfoot style={{ textAlign: "center" }}>
          <tr>
            <th colSpan={3}>&nbsp;</th>
            <th colSpan={1}>Total $</th>
            <th colSpan={1}>&nbsp;</th>
            <th className="border" colSpan={1}>
              {formatMoneyUSD(totals.resellerTotalPriceUSD)}
            </th>
            <th colSpan={1}>&nbsp;</th>
            <th className="border" colSpan={1}>
              {formatMoneyUSD(totals.invoiceTotalPriceUSD)}
            </th>
            <th colSpan={1}>&nbsp;</th>
            <th className="border" colSpan={1}>
              {formatMoneyBRL(totals.invoiceTotalPriceBRL)}
            </th>
          </tr>
        </tfoot>
      </table>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <NavLink to="/rma" className="btn btn-secondary" type="button">
          Voltar
        </NavLink>

        {allowCancel && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleOpenModal}
            disabled={!selItems.length}
          >
            Cancelar itens
          </button>
        )}
      </div>

      <ModalCancelItemRma
        isShown={confirmModalOpened}
        isLoading={isLoading}
        onConfirm={handleCancelItems}
        onClose={() => setConfirmModalOpened(false)}
      />
    </Card>
  );
};

export default TableDetailRma;
