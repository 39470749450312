import {
  CancelOrderRmaDto,
  FilterRmaList,
  RmaOrdersResponse,
} from "../../models/Rma";
import apiClient from "./ApiClient";
import { handleAxiosError } from "../../utils/handleAxiosError";

export const getRmaOrders = async (
  filters: FilterRmaList
): Promise<RmaOrdersResponse> => {
  try {
    let response = await apiClient.get<RmaOrdersResponse>("rmas", {
      params: filters,
    });

    return response.data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};

export const cancelOrderItemsRma = async (
  request: CancelOrderRmaDto
): Promise<void> => {
  try {
    const response = await apiClient.put(`rmas`, request);

    return response.data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
