import { OptionsProps } from "../OptionsProps";

export enum orderStatus {
  All = -1,
  Draft,
  CreditAnalysis,
  CreditDenied,
  FinancialRefused,
  WaitingSendingAdobe,
  Processed,
  WaitingSendingBilling,
  Billed,
  ErrorSendingAdobe,
  PendingRenewal,
}

export const orderStatusMap: { [key in orderStatus]: OptionsProps } = {
  [orderStatus.All]: { id: "-1", text: "Todos" },
  [orderStatus.Draft]: { id: "Draft", text: "Rascunho" },
  [orderStatus.CreditAnalysis]: { id: "CreditAnalysis", text: "Análise de Crédito" },
  [orderStatus.CreditDenied]: { id: "CreditDenied", text: "Crédito Negado" },
  [orderStatus.FinancialRefused]: { id: "FinancialRefused", text: "Recusado Financeiro" },
  [orderStatus.WaitingSendingAdobe]: { id: "WaitingSendingAdobe", text: "Aguardando Envio Adobe" },
  [orderStatus.Processed]: { id: "Processed", text: "Processado" },
  [orderStatus.WaitingSendingBilling]: { id: "WaitingSendingBilling", text: "Aguardando Envio Faturamento" },
  [orderStatus.Billed]: { id: "Billed", text: "Faturado" },
  [orderStatus.ErrorSendingAdobe]: { id: "ErrorSendingAdobe", text: "Erro no Envio para Adobe" },
  [orderStatus.PendingRenewal]: { id: "PendingRenewal", text: "Renovação Pendente" },
};

export const toOrderStatusText = (id: string): string => {
  for (const key in orderStatus) {
    const status = orderStatus[key as keyof typeof orderStatus];
    if (typeof status !== "number") continue;

    const mappedStatus = orderStatusMap[status];
    if (mappedStatus && mappedStatus.id === id) return mappedStatus.text;
  }

  return "Desconhecido";
};

export const orderStatusMapOptions = (): OptionsProps[] => {
  return Object.values(orderStatusMap).sort((a, b) => {
    let idA = a.text || "";
    let idB = b.text || "";

    if (idA === "Todos") idA = "-1";
    if (idB === "Todos") idB = "-1";

    return idA.localeCompare(idB);
  });  
}