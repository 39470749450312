import React, { useState, useEffect, useRef } from "react";

interface InputCurrencyProps {
    id?: string;
    name?: string;
    label?: string;
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange: (value: number) => void;
}

export const InputCurrency: React.FC<InputCurrencyProps> = ({
    id,
    name,
    label,
    value,
    minValue,
    maxValue,
    onChange,
}) => {
    const formatToBrazilianCurrency = (num: number): string => {
        return num
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const [inputValue, setInputValue] = useState<string>(
        formatToBrazilianCurrency(value)
    );

    const inputRef = useRef<HTMLInputElement>(null);

    const parseBrazilianCurrency = (val: string): number => {
        const normalizedValue = val.replace(/\./g, "").replace(",", ".");
        const num = parseFloat(normalizedValue);
        return isNaN(num) ? 0 : num;
    };

    const validateValue = (num: number): number => {
        if (minValue !== undefined && num < minValue) return minValue;
        if (maxValue !== undefined && num > maxValue) return maxValue;
        return num;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/\D/g, "");
        const numericValue = parseInt(rawValue, 10) || 0;
        const formattedValue = formatToBrazilianCurrency(numericValue / 100);

        setInputValue(formattedValue);
        onChange(validateValue(numericValue / 100));
    };

    const handleBlur = () => {
        const numericValue = parseBrazilianCurrency(inputValue);
        const validatedValue = validateValue(numericValue);

        setInputValue(formatToBrazilianCurrency(validatedValue));
        onChange(validatedValue);
    };

    useEffect(() => {
        setInputValue(formatToBrazilianCurrency(value || 0));
    }, [value]);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
        }
    }, [inputValue]);

    return (
        <div className="form-group">
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={name}
                type="text"
                className="form-control my-2"
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
            />
        </div>
    );
};
