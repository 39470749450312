import React, { useEffect, useState } from "react";
import { Modal } from "../../../components/mols/Modal";
import { Input } from "../../../components/atoms/Input";
import { useNavigate } from "react-router-dom";
import { getOrderById, putNewOrder } from "../../../services/api/OrderService";
import { OrderCreation } from "../../../models/Order";
import { useToast } from "../../../components/atoms/Toast";
import { storage } from "../../../utils/storage";
import { Loader } from "../../../components/atoms/Loader";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

interface QuotationModalProps {
  isShown: boolean;
  isRenewal: boolean | null;
  isThreeYears: boolean;
  customerId: number | null;
  isEnabled: boolean;
  onClose: () => void;
}

export const QuotationModal: React.FC<QuotationModalProps> = ({
  isShown,
  isRenewal,
  isThreeYears,
  customerId,
  isEnabled,
  onClose,
}) => {
  const [link, setLink] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [message, setMessage] = useState<string | React.ReactNode>("");

  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const handleClose = () => {
    setLink("");
    onClose();
  };

  const handleSubmit = async () => {
    try {
      if (!customerId) throw new Error("Falha ao identificar o cliente");
      if (isRenewal === null)
        throw new Error("Falha ao identificar o tipo de pedido");

      setIsLoading(true);

      const orderDto: OrderCreation = {
        customerId,
        isRenewal,
        hubspotLink: link,
      };
      const order = await putNewOrder(orderDto);
      const currentOrder = await getOrderById(order.id);
      storage.set("currentOrder", currentOrder);

      const url = isRenewal || link !== "" ? "/order/cart" : `/order/products`;
      navigate(url);
    } catch (ex) {
      handleError(ex, toast, "criar um novo pedido");
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isEnabled) {
      setTitle("Cliente indisponível");
      setIsLoading(false);
      setText("Não é possível realizar um pedido para esse cliente.");
      setMessage("Entre em contato com a equipe PARS.");
    } else if (isThreeYears) {
      setTitle("Anexe sua cotação");
      setIsLoading(false);
      setText("Insira o link do HubSpot no campo abaixo:");

      setMessage(
        <small className="text-muted">
          Para este cliente só está disponível compras via cotação do HubSpot.
          <br />
          Em caso de dúvidas, contate a equipe PARS.
        </small>
      );
    } else {
      setTitle("Possui alguma cotação?");
      setIsLoading(false);
      setText("Se sim insira abaixo.");
      setMessage("Se não possui cotação, apenas clique em Continuar.");
    }
  }, [isEnabled, isThreeYears]);

  return (
    <Modal
      isShown={isShown}
      title={title}
      body={
        <>
          <p className="mb-2">{text}</p>
          {isEnabled && (
            <Input
              id="quotationLink"
              placeholder="Cole o link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              disabled={isLoading}
            />
          )}
          <p>{message}</p>
        </>
      }
      footer={
        <div className="w-100 d-flex justify-content-center gap-2">
          <button
            className="btn btn-secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancelar {isLoading && <Loader />}
          </button>
          {isEnabled && (
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Continuar {isLoading && <Loader />}
            </button>
          )}
        </div>
      }
      onClose={handleClose}
    />
  );
};
