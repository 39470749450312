import React, { useState } from "react";
import { Input } from "../../../components/atoms/Input";
import { Select } from "../../../components/atoms/Select";
import { CustomerContacts as Customercontact } from "../../../models/Customer";
import { TypeContact } from "../../../models/enums/TypeContact";
import { useToast } from "../../../../src/components/atoms/Toast";
import { CustomerForm, EditCustomerForm } from "../models/CustomerForm";
import { contactTypes } from "../customerEnum/ContactTypes";
import { resendAdminInvite } from "../../../services/api/CustomerService"
import { SweetAlertIcon } from "sweetalert2";

interface CustomerContactsProps {
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSelectChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleNumberInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: EditCustomerForm | CustomerForm;
  disabled?: boolean;
}

export const CustomerContacts: React.FC<CustomerContactsProps> = ({
  handleSelectChange,
  handleInputChange,
  handleNumberInputChange,
  formData,
  disabled = false,
}) => {
  const toast = useToast();
  const [isLoadingSendInvite, setIsLoadingSendInvite] = useState<boolean>(false);

  const showToast = (icon: SweetAlertIcon, title: string) => {
    toast.fire({ icon, title });
  };

  const validateResponsible = (): boolean => {
    const isEmpty = (value: string | null | undefined) => 
      !value || value.trim().replace(" ", "") === "" || value === "null";
  
    if (isEmpty(formData.signingResponsibleName) || 
        formData.signingResponsibleName.trim().split(" ").length < 2) {
      showToast("warning", "O campo Nome Completo do Responsável da Assinatura deve conter nome e sobrenome.");
      return false;
    }
  
    if (isEmpty(formData.signingResponsibleNumber)) {
      showToast("warning", "O campo Telefone do Responsável da Assinatura deve ser preenchido.");
      return false;
    }
  
    if (isEmpty(formData.signingResponsibleTypePhone) || 
        formData.signingResponsibleTypePhone === "0") {
      showToast("warning", "Selecione o Tipo de Telefone do Responsável da Assinatura.");
      return false;
    }
  
    if (isEmpty(formData.signingResponsibleEmail)) {
      showToast("warning", "O campo Email do Responsável da Assinatura deve ser preenchido.");
      return false;
    }
  
    return true;
  }; 

  const handleSendInvite = async () => {
    if (formData.id !== null && formData.id !== undefined) {
      if (validateResponsible()) {
        try {
          setIsLoadingSendInvite(true);

          const contact: Customercontact = {
            id: 0,
            name: formData.signingResponsibleName,
            email: formData.signingResponsibleEmail,
            typeContact: TypeContact.Signature,
            phoneNumber: formData.signingResponsibleNumber,
            typePhone: formData.signingResponsibleTypePhone,
            customerId: formData.id,
          };

          await resendAdminInvite(contact);

        } catch (error) {
          if (error.status === 406) {
            showToast("warning", error.response.data.message);
          } else {
            showToast("error","Falha ao reenviar convite");
          }
        } finally {
          setIsLoadingSendInvite(false);

          showToast("success","Convite enviado com sucesso!");
        }
      }
    } else {
      return;
    }
  };

  return (
    <>
      {formData.id !== null && formData.id !== undefined ? (
        <div className="row">
          <div className="col col-12">
            <strong>Responsavel pela assinatura</strong>
          </div>
          <div className="col col-3">
            <Input
              label="Nome Completo *"
              id="signingResponsibleName"
              onChange={handleInputChange}
              value={formData?.signingResponsibleName}
              disabled={disabled}
              placeholder="-"
            />
          </div>
          <div className="col col-2">
            <Select
              label="Tipo *"
              options={contactTypes}
              id="signingResponsibleTypePhone"
              onChange={handleSelectChange}
              value={formData?.signingResponsibleTypePhone}
              disabled={disabled}
            />
          </div>
          <div className="col col-2">
            <Input
              label="Telefone *"
              id="signingResponsibleNumber"
              placeholder={formData?.signingResponsibleTypePhone === "Telephone"
                ? "(00) 0000-0000"
                : "(00) 00000-0000"}
              mask={formData?.signingResponsibleTypePhone === "Telephone"
                ? "(99) 9999-9999"
                : "(99) 99999-9999"}
              onChange={handleNumberInputChange}
              value={formData?.signingResponsibleNumber}
              disabled={disabled}
            />
          </div>
          <div className="col col-3">
            <Input
              label="Email *"
              id="signingResponsibleEmail"
              type="email"
              onChange={handleInputChange}
              value={formData?.signingResponsibleEmail}
              disabled={disabled}
              placeholder="-"
            />
          </div>
          <div className="col col-2">
            <button className="btn btn-primary btn-sm form-control" type="button" style={{ marginTop: '35px' }}
              onClick={handleSendInvite}
              disabled={isLoadingSendInvite}>

              {isLoadingSendInvite ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <i className="fa fa-fw fa-paper-plane"></i>
              )}
              Reenviar convite
            </button>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col col-12">
            <strong>Responsavel pela assinatura</strong>
          </div>
          <div className="col col-4">
            <Input
              label="Nome Completo *"
              id="signingResponsibleName"
              onChange={handleInputChange}
              value={formData?.signingResponsibleName}
              disabled={disabled}
              placeholder="-"
            />
          </div>
          <div className="col col-2">
            <Select
              label="Tipo *"
              options={contactTypes}
              id="signingResponsibleTypePhone"
              onChange={handleSelectChange}
              value={formData?.signingResponsibleTypePhone}
              disabled={disabled}
            />
          </div>
          <div className="col col-3">
            <Input
              label="Telefone *"
              id="signingResponsibleNumber"
              placeholder={formData?.signingResponsibleTypePhone === "Telephone"
                ? "(00) 0000-0000"
                : "(00) 00000-0000"}
              mask={formData?.signingResponsibleTypePhone === "Telephone"
                ? "(99) 9999-9999"
                : "(99) 99999-9999"}
              onChange={handleNumberInputChange}
              value={formData?.signingResponsibleNumber}
              disabled={disabled}
            />
          </div>
          <div className="col col-3">
            <Input
              label="Email *"
              id="signingResponsibleEmail"
              type="email"
              onChange={handleInputChange}
              value={formData?.signingResponsibleEmail}
              disabled={disabled}
              placeholder="-"
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col col-12">
          <strong>Responsavel pela compra</strong>
        </div>
        <div className="col col-4">
          <Input
            label="Nome Completo"
            id="purchasingResponsibleName"
            onChange={handleInputChange}
            value={formData?.purchasingResponsibleName}
            disabled={disabled}
            placeholder="-"
          />
        </div>
        <div className="col col-2">
          <Select
            label="Tipo"
            options={contactTypes}
            id="purchasingResponsibleTypePhone"
            onChange={handleSelectChange}
            value={formData?.purchasingResponsibleTypePhone}
            disabled={disabled}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Telefone"
            id="purchasingResponsibleNumber"
            placeholder={formData?.purchasingResponsibleTypePhone === "Telephone"
              ? "(00) 0000-0000"
              : "(00) 00000-0000"}
            mask={formData?.purchasingResponsibleTypePhone === "Telephone"
              ? "(99) 9999-9999"
              : "(99) 99999-9999"}
            onChange={handleNumberInputChange}
            value={formData?.purchasingResponsibleNumber}
            disabled={disabled}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Email"
            id="purchasingResponsibleEmail"
            type="email"
            onChange={handleInputChange}
            value={formData?.purchasingResponsibleEmail}
            disabled={disabled}
            placeholder="-"
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-12">
          <strong>Responsavel pelo financeiro</strong>
        </div>
        <div className="col col-4">
          <Input
            label="Nome Completo"
            id="financialResponsibleName"
            onChange={handleInputChange}
            value={formData?.financialResponsibleName}
            disabled={disabled}
            placeholder="-"
          />
        </div>
        <div className="col col-2">
          <Select
            label="Tipo"
            options={contactTypes}
            id="financialResponsibleTypePhone"
            onChange={handleSelectChange}
            value={formData?.financialResponsibleTypePhone}
            disabled={disabled}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Telefone"
            id="financialResponsibleNumber"
            placeholder={formData?.financialResponsibleTypePhone === "Telephone"
              ? "(00) 0000-0000"
              : "(00) 00000-0000"}
            mask={formData?.financialResponsibleTypePhone === "Telephone"
              ? "(99) 9999-9999"
              : "(99) 99999-9999"}
            onChange={handleNumberInputChange}
            value={formData?.financialResponsibleNumber}
            disabled={disabled}
          />
        </div>
        <div className="col col-3">
          <Input
            label="Email"
            id="financialResponsibleEmail"
            type="email"
            onChange={handleInputChange}
            value={formData?.financialResponsibleEmail}
            disabled={disabled}
            placeholder="-"
          />
        </div>
      </div>
    </>
  );
};
