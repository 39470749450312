import React, { useState, useRef, useEffect, useCallback } from "react";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { Card } from "../../components/mols/Card";
import { Input } from "../../components/atoms/Input";
import { Modal } from "../../components/mols/Modal";
import { Icon } from "../../components/atoms/Icon";
import {
  getCustomerMigrations,
  submitMigration,
  submitMigrationByTransferID,
} from "../../services/api/CustomerMigrationService";
import $ from "jquery";
import ptbr from "../../assets/lib/js/dataTables/pt-br.json";
import { useToast } from "../../components/atoms/Toast";

import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-buttons-bs5";

import JSZip from "jszip";
// @ts-ignore
window.JSZip = JSZip;

export const CustomerMigration: React.FC = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [VipMembershipID, setVIPMembershipID] = useState("");
  const [resellerId, setResellerId] = useState("");
  const tableRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transferID, setTransferID] = useState("");

  const handleSubmit = async () => {
    try {
      let requestIdNumber: number = 0;

      if (resellerId.trim().length > 0)
        requestIdNumber = Number.parseInt(resellerId.trim());

      const request = {
        VipMembershipID: VipMembershipID,
        resellerId: requestIdNumber,
      };

      if (
        resellerId == null ||
        resellerId == undefined ||
        resellerId.trim().length == 0 ||
        VipMembershipID == null ||
        VipMembershipID == undefined ||
        VipMembershipID.trim().length == 0
      ) {
        if (
          transferID == null ||
          transferID == undefined ||
          transferID.trim().length == 0
        ) {
          Toast.fire({
            icon: "warning",
            title: "Preencha o TransferID ou VipMembershipID e a Revenda.",
          });

          return;
        } else {
          setIsLoading(true);
          await submitMigrationByTransferID(transferID);
        }
      } else {
        setIsLoading(true);
        await submitMigration(request);
      }

      setShowModal(false);
      setIsLoading(false);
      const table = $(tableRef.current).DataTable();
      table.ajax.reload();
    } catch (error) {
      setIsLoading(false);
      console.error("Erro ao enviar:", error);
    }
  };

  const clearModalFields = () => {
    setVIPMembershipID("");
    setResellerId("");
  };

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        ajax: (dataTablesParams: any, callback: (data: any) => void) => {
          const currentPage = Math.floor(
            dataTablesParams.start / dataTablesParams.length
          );
          const size = dataTablesParams.length;
          getCustomerMigrations(currentPage, size, filter)
            .then((customer) => {
              callback({
                data: customer,
              });
            })
            .catch((error) => console.error("Erro ao buscar dados:", error));
        },
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excelHtml5",
            text: '<i class="fa fa-file-excel"></i>',
            filename: "Importacoes",
            className: "btn btn-success",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csvHtml5",
            text: '<i class="fa fa-file-csv"></i>',
            filename: "Importacoes",
            className: "btn btn-info",
            exportOptions: {
              columns: ":visible",
            },
            init: function (api, node) {
              $(node).css("margin-left", "5px");
            },
          },
        ],
        language: ptbr,
        columns: [
          { data: "customerId", width: "20%", type: "string" },
          { data: "resellerIdAdobe", width: "20%", type: "string" },
          { data: "approvalCode", width: "40%", type: "string" },
          { data: "status", width: "20%", type: "string" },
        ],
        ordering: false,
      });

      return () => {
        if ($.fn.dataTable.isDataTable(tableRef.current)) {
          $(tableRef.current).DataTable().destroy();
        }
      };
    }
  }, [filter]);

  return (
    <MainTemplate>
      <Card className="mb-3">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const table = $(tableRef.current).DataTable();
            table.ajax.reload();
          }}
        >
          <div className="d-flex justify-content-between">
            <h1 className="h3">Migração de Cliente</h1>
          </div>

          <div className="row mt-3">
            <div className="col">
              <Input
                placeholder="Pesquise pelo VIP Membership ID ou TransferID"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <div className="col-auto d-flex justify-content-end gap-3 gap-3">
              <button
                className="btn btn-secondary"
                id="clearFilter"
                type="reset"
                onClick={() => {
                  setFilter("");
                }}
              >
                Limpar Filtro
              </button>
              <button className="btn btn-primary" type="submit">
                Filtrar <Icon className="fa fa-fw fa-filter" />
              </button>
            </div>
          </div>
        </form>
      </Card>

      <Card>
        <table ref={tableRef} className="table table-sm table-striped">
          <thead>
            <tr>
              <th className="text-start">Membership ID</th>
              <th>Reseller ID</th>
              <th>Transfer ID</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              setShowModal(true);
              clearModalFields();
            }}
          >
            <Icon className="fa fa-plus" /> Nova Migração
          </button>
        </div>
      </Card>

      <Modal
        isShown={showModal}
        title="Solicitação de Migração de Cliente"
        body={
          <form>
            <div className="mb-3">
              <p>
                <strong>
                  Informe a Revenda e o VipMembershipID ou o Transfer ID
                </strong>
              </p>
              <Input
                placeholder="RevendaId"
                value={resellerId}
                onChange={(e) => setResellerId(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <Input
                placeholder="VipMembershipID"
                value={VipMembershipID}
                onChange={(e) => setVIPMembershipID(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Input
                placeholder="Transfer ID"
                value={transferID}
                onChange={(e) => setTransferID(e.target.value)}
              />
            </div>
          </form>
        }
        footer={
          <>
            <button
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
              disabled={isLoading}
            >
              Cancelar
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Enviar
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </>
        }
        onClose={() => setShowModal(false)}
      />
    </MainTemplate>
  );
};
