import { OptionsProps } from "../OptionsProps";

export enum BillingFor {
  None = "Select", 
  Reseller = "Reseller",
  Customer = "Customer",
}

export const billingForMap: { [key in BillingFor]: OptionsProps } = {
  [BillingFor.None]: { id: BillingFor.None, text: "Selecione" }, 
  [BillingFor.Reseller]: { id: BillingFor.Reseller, text: "Revenda" },
  [BillingFor.Customer]: { id: BillingFor.Customer, text: "Cliente" },
};