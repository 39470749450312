import {MainTemplate} from "../../../components/templates/MainTemplate";
import {OrdersHistoryFilterList} from "./OrdersHistoryFilters";
import {OrdersHistoryDataTable} from "./OrdersHistoryDataTable";
import {useCallback, useState} from "react";
import {orderType} from "../../../models/enums/orderType";
import {orderStatus} from "../../../models/enums/orderStatus";

export interface OrderHistoryFilterRequest {
    page: number;
    size: number;
    startDate?: Date | string | null;
    endDate?: Date | string | null;
    reseller?: string | null;
    customer?: string | null;
    customerId?: number | null;
    type?: orderType | null;
    status?: orderStatus | null;
    value?: number | null;
    pedCustomer?: string | null;
    pedReseller?: string | null;
    pedAdobe?: string | null;
    orderId?: number | null;
}

const OrderHistoryList: React.FC = () => {
    const [filters, setFilters] = useState<OrderHistoryFilterRequest>({
        page: 0,
        size: 20,
        startDate: null,
        endDate: null,
        reseller: "",
        customer: "",
        type: orderType.All,
        status: orderStatus.All,
        value: 0,
        pedCustomer: "",
        pedReseller: "",
        pedAdobe: "",
        orderId: null
    });

    const handleSearch = useCallback((form: OrderHistoryFilterRequest): void => {
        setFilters({...form, page: 0, size: 20});
    }, []);

    return (
        <MainTemplate>
            <OrdersHistoryFilterList onSearch={handleSearch}/>
            <OrdersHistoryDataTable filters={filters}/>
        </MainTemplate>
    );
};

export default OrderHistoryList;

