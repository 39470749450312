import { useRef, useState } from "react";
import {
  orderStatus,
  orderStatusMapOptions,
} from "../../../models/enums/orderStatus";
import { orderType } from "../../../models/enums/orderType";
import { Card } from "../../../components/mols/Card";
import { Input } from "../../../components/atoms/Input";
import { Select } from "../../../components/atoms/Select";
import { Icon } from "../../../components/atoms/Icon";
import { useForm } from "../../../hooks/formHook";
import { InputCurrency } from "../../../components/atoms/InputCurrency";

export interface OrderHistoryFilterForm {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  reseller?: string | null;
  customer?: string | null;
  type?: orderType | null;
  status?: orderStatus | null;
  value?: number | null;
  pedCustomer?: string | null;
  pedReseller?: string | null;
  pedAdobe?: string | null;
  orderId?: number | null;
}

export interface OrderHistoryFilterFormProps {
  onSearch(form: OrderHistoryFilterForm): void;
}

export const OrdersHistoryFilterList: React.FC<OrderHistoryFilterFormProps> = ({
  onSearch,
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [orderHistoryForm, setOrderHistoryForm] =
    useState<OrderHistoryFilterForm>({
      startDate: null,
      endDate: null,
      reseller: "",
      customer: "",
      type: orderType.All,
      status: orderStatus.All,
      value: 0,
      pedCustomer: "",
      pedReseller: "",
      pedAdobe: "",
      orderId: null
    });

  const profile = JSON.parse(localStorage.getItem("authUser")!).profile;
  const isReseller = profile.isReseller;

  const { handleInputChange } = useForm<OrderHistoryFilterForm>({
    setFormData: setOrderHistoryForm,
  });

  const validateForm = ({
    orderHistoryFilterForm,
  }: {
    orderHistoryFilterForm: OrderHistoryFilterForm;
  }): void => {
    const { customer, reseller, pedCustomer, pedReseller, pedAdobe } =
      orderHistoryFilterForm;

    setOrderHistoryForm((prev) => {
      return {
        ...prev,
        customer: customer?.trim() === "" ? null : customer,
        reseller: reseller?.trim() === "" ? null : reseller,
        pedCustomer: pedCustomer?.trim() === "" ? null : pedCustomer,
        pedReseller: pedReseller?.trim() === "" ? null : pedReseller,
        pedAdobe: pedAdobe?.trim() === "" ? null : pedAdobe,
      };
    });
  };

  const handleFilter = (e: React.FormEvent) => {
    e.preventDefault();
    validateForm({ orderHistoryFilterForm: orderHistoryForm });
    onSearch(orderHistoryForm);
  };

  return (
    <Card className="mb-3">
      <form ref={formRef} onSubmit={handleFilter}>
        <h1 className="h3">Histórico de Pedidos</h1>

        <div className="row mb-2">
          <div className="col-2">
            <Input
              label="Data Inicial"
              type="date"
              value={orderHistoryForm.startDate}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  startDate: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-2">
            <Input
              label="Data Final"
              type="date"
              value={orderHistoryForm.endDate}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  endDate: e.target.value,
                }))
              }
            />
          </div>
          <div className={isReseller ? "col-6" : "col-4"}>
            <Input
              id="customers"
              label="Customer"
              placeholder="Customer"
              value={orderHistoryForm.customer}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  customer: e.target.value,
                }))
              }
            />
          </div>
          {!isReseller && (
            <div className="col-4">
              <Input
                id="resellers"
                label="Reseller"
                placeholder="Reseller"
                value={orderHistoryForm.reseller}
                onChange={(e) =>
                  setOrderHistoryForm((form) => ({
                    ...form,
                    reseller: e.target.value,
                  }))
                }
              />
            </div>
          )}
        </div>

        <div className="row mb-2">
          <div className="col-2">
            <Select
              label="Tipo"
              id="type"
              options={[
                { id: orderType.All.toString(), text: "Todos" },
                { id: orderType.New.toString(), text: "Novo Pedido" },
                { id: orderType.Renewal.toString(), text: "Renovação" },
              ]}
              onChange={handleInputChange}
              value={orderHistoryForm.type?.toString()}
            />
          </div>
          <div className="col-2">
            <Select
              label="Status"
              id="status"
              options={orderStatusMapOptions()}
              onChange={handleInputChange}
              value={orderHistoryForm.status?.toString()}
            />
          </div>
          <div className="col-2">
            <InputCurrency
              label="Valor"
              value={orderHistoryForm.value || 0}
              onChange={(newValue) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  value: newValue || null,
                }))
              }
            />
          </div>
          <div className="col-3">
            <Input
              label="Ped. Cliente"
              type="text"
              placeholder="Ped. Cliente"
              value={orderHistoryForm.pedCustomer}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  pedCustomer: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-3">
            <Input
              label="Ped. Revenda"
              type="text"
              placeholder="Ped. Revenda"
              value={orderHistoryForm.pedReseller}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  pedReseller: e.target.value,
                }))
              }
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <Input
              label="Ped. Adobe"
              type="text"
              placeholder="Ped. Adobe"
              value={orderHistoryForm.pedAdobe}
              onChange={(e) =>
                setOrderHistoryForm((form) => ({
                  ...form,
                  pedAdobe: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-3">
            <Input
              label="Ped. Connect Hub"
              type="text"
              placeholder="Ped. Connect Hub"
              value={orderHistoryForm.orderId || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ''); 
                setOrderHistoryForm((form) => ({
                  ...form,
                  orderId: value ? Number(value) : null
                }));
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary"
            id="clearFilter"
            type="reset"
            onClick={() => {
              setOrderHistoryForm({
                startDate: null,
                endDate: null,
                reseller: "",
                customer: "",
                type: orderType.All,
                status: orderStatus.All,
                value: 0,
                pedCustomer: "",
                pedReseller: "",
                pedAdobe: "",
                orderId: null
              });
            }}
          >
            Limpar Filtro
          </button>

          <button
            className="btn btn-primary"
            style={{ marginLeft: "5px" }}
            type="submit"
          >
            Filtrar <Icon className="fa fa-fw fa-filter" />
          </button>
        </div>
      </form>
    </Card>
  );
};
