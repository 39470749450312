import apiClient from "./ApiClient";
import { CustomerMigration } from "../../models/CustomerMigration";

const apiConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getCustomerMigrations = async (
  page: number,
  size: number,
  filter?: string
): Promise<{ content: CustomerMigration[]; total: number }> => {
  const url = `/customermigration?Page=${page}&Size=${size}`;
  const response = await apiClient.get<any>(url);

  return {
    content: response.data.content as CustomerMigration[],
    total: response.data.total,
  };
};

export const submitMigration = async (request: {
  VipMembershipID: string;
  resellerId: number;
}) => {
  const response = await apiClient.post(
    "/customermigration/membership-reseller",
    request
  );
  return response.data;
};
export const submitMigrationByTransferID = async (transferId: string) => {
  const response = await apiClient.post(
    "/customermigration/by-transferId",
    transferId
  );
  return response.data;
};
